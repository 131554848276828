"use client";
import { useContext, useEffect, useState } from "react";
import { strings } from "../strings";
import { Carousel, CustomFlowbiteTheme } from "flowbite-react";
import logo from "../tabler_icons.png";
import { UserContext } from "../Providers/UserContext";
import { ComunaAPI } from "../managers/ComunaAPI";
import { Loader } from "./loader";
import CarouselIndicator from "./CarouselIndicator";
import TextSlide from "./TextSlide";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { useLoaderData } from "react-router-dom";

//this comes from react router, the function that runs to load the info of this page.
export async function loader({ params }) {
	var token = await LocalStorageManager.getInitToken();

	if (token) {
		return { token };
	}
}

export function ClientOnboarding() {
	const coinsIcon = require("../img/icons/coins-stacked-01.png");
	const awardIcon2 = require("../img/icons/award-04.png");
	const fileShieldIcon2 = require("../img/icons/file-shield-02.png");
	const globeIcon = require("../img/icons/globe-05.png");
	const userCheckIcon2 = require("../img/icons/image-user-check.png");
	const slidersIcon2 = require("../img/icons/sliders-02.png");
	const searhIcon = require("../img/icons/file-search.png");
	const checkIcon = require("../img/icons/file-check.png");
	const chatIcon = require("../img/icons/message-chat.png");
	const globeicon = require("../img/icons/globe.png");
	const userCheckIcon = require("../img/icons/user-check.png");
	const awardIcon = require("../img/icons/award.png");
	const slidersIcon = require("../img/icons/sliders.png");
	const fileShieldIcon = require("../img/icons/file-shield.png");

	const graphic_2 = require("../img/graphics/graphic_2.jpg");
	const logo = require("../img/muna-logo.png");
	const img1 = require("../img/graphics/graphic_1.jpg");
	const slide1 = require("../img/graphics/onboarding/slide1.png");
	const slide2 = require("../img/graphics/onboarding/slide2.png");
	const slide3 = require("../img/graphics/onboarding/slide3.png");
	const slide4 = require("../img/graphics/onboarding/slide4.png");
	const slide5 = require("../img/graphics/onboarding/slide5.png");
	const slide6 = require("../img/graphics/onboarding/slide6.png");
	const slide7 = require("../img/graphics/onboarding/slide7.png");

	const customTheme = {
		root: {
			base: "relative h-full w-full",
			leftControl:
				"absolute top-0 left-0 flex h-full items-center justify-center px-4",
			rightControl:
				"absolute top-0 right-0 flex h-full items-center justify-center px-4",
		},
		indicators: {
			active: {
				off: "bg-white/50 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800",
				on: "bg-comuna-purple dark:bg-gray-800",
			},
			base: "h-3 w-3 rounded-full",
			wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3",
		},
		item: {
			base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
			wrapper: {
				off: "w-full flex-shrink-0 transform cursor-default snap-center",
				on: "w-full flex-shrink-0 transform cursor-grab snap-center",
			},
		},
		control: {
			base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10",
			icon: "h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6",
		},
		scrollContainer: {
			base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg",
			snap: "snap-x",
		},
	};

	const features = [
		{
			icon: coinsIcon,
			title: "Greater transparency, lower cost",
			description:
				"Ditch the old outsourcing model that squeezes margins (upwards of 60-100%) by hiking fees and slashing talent pay. It hurts clients and workers alike, and we can't stand it! We're the anti-middleman, championing transparency with a fair 20% fee, covering everything from headhunting to equipment, benefits, payroll, and global legalities. Salary details are completely transparent with us. Expect no hidden fees, only fair play.",
		},
		{
			icon: globeIcon,
			title: "Access hidden talent",
			description:
				"MUNA liberates LATAM talent from outsourcing woes, offering direct links to US firms—more career control, fuller paychecks, no middleman meddling. Plus, with MUNA's help in securing top-tier benefits, talent enjoys a VIP experience akin to their US counterparts",
		},
		{
			icon: userCheckIcon2,
			title: "The right candidate the first time",
			description:
				"Finding the right tech talent to fit your cultural and technical needs is difficult, especially across global legal and language barriers. With 20 years in LATAM tech recruitment, we curate candidates with precision—culture, tech, and communication vetted. Just 13 days to hire, our promise: the first batch of candidates are already top-tier. No time wasted, just talent acquired.",
		},
		{
			icon: awardIcon2,
			title: "Best-in-class rewards",
			description:
				"Unlock local insights for top-tier salary and benefits, crafting unbeatable rewards with just a few clicks on MUNA. Offer everything from PTO to weekly lunches and quality gear—ensuring your global team enjoys a consistent, premium experience. Say goodbye to isolated contractors and cultural compromises.",
		},
		{
			icon: slidersIcon2,
			title: "All-in-one platform",
			description:
				"Streamline your hiring and team management on MUNA. Build your global dream team effortlessly, extend offers, and once on board, easily oversee your roster, handle payroll, update legal docs, and enhance compensation with bonuses and rewards—all within one intuitive platform.",
		},
		{
			icon: fileShieldIcon2,
			title: "Stress-free compliance and payroll",
			description:
				"Our mission: Seamless US-LATAM talent connections. Ideally, firms would self-navigate LATAM's complex legal and tax maze, but it's a headache.  The MUNA team, with over 20 years of experience, has distilled its mastery of LATAM's complex legal landscape into an automated platform—saving you the intricate legwork.",
		},
	];

	const { token } = useLoaderData();
	const [loading, setLoading] = useState(false);
	const [verified, setVerified] = useState(false);
	const [openModal, setOpenModal] = useState(true);
	const props = { openModal, setOpenModal };

	const [verifying, setVerifying] = useState(false);
	const [verifyError, setVerifyError] = useState(false);

	const [verifyCode, setVerifyCode] = useState("");

	const [slideStep, setSlideStep] = useState(0);

	async function verifyEmail1() {
		const verifyEmailReturn = await ComunaAPI.step1VerifyEmail(token);
		setVerifying(true);
		console.log(verifyEmailReturn);
	}

	async function verifyEmail2() {
		setVerifyError(false);
		if (verifyCode.length < 4) {
			setVerifyError(true);
			return;
		}

		setLoading(true);
		var noIssues = true;
		try {
			const verifyEmailReturn = await ComunaAPI.step2VerifyEmail(
				token,
				verifyCode
			);
		} catch {
			noIssues = false;
			setVerifyError(true);
		} finally {
			if (noIssues) {
				LocalStorageManager.setToken(token);
				var wantedURL = LocalStorageManager.getWantedURL();

				setTimeout(() => {
					// window.location.href = "/hiring";
					if (LocalStorageManager.getWantedURL() !== "") {
						console.log("found wantedURL: " + wantedURL);
						// LocalStorageManager.setWantedURL("");
						window.location.href = wantedURL;
					} else {
						window.location.href = "/hiring";
					}
				}, 0.2);
			}
			setLoading(false);
		}
	}

	function Feature(props) {
		return (
			<div className="comuna-feature">
				<img
					className="comuna-small-icon"
					src={props.icon}
					alt="illustrative icon"
				/>
				<h2>{props.title}</h2>
				<p className="text-xs">{props.description}</p>
			</div>
		);
	}

	function onSlideChange(index) {
		console.log("onSlideChange()", index);
		var offset = index * 100;
		var hOffset = -offset;
		console.log("h offset: ", hOffset);
		document.querySelector("#onboarding-v-slides").style.top = -offset + "%";
		document.querySelector("#onboarding-h-slides").style.left = hOffset + "%";
	}

	useEffect(() => {
		onSlideChange(slideStep);
	}, [slideStep]);

	useEffect(() => {
		console.log("got initToken: ", token);
	}, [token]);

	function carouselBack() {
		if (slideStep > 0) {
			setSlideStep(slideStep - 1);
		}
	}

	function Slide({ img }) {
		return (
			<div
				style={{
					backgroundImage: `url(${img})`,
					width: "100%",
					height: "100%",
					backgroundSize: "cover",
				}}
			/>
		);
	}

	function carouselForward() {
		console.log("lenght: ", features.length, " step: ", slideStep);
		if (slideStep < features.length - 1) {
			setSlideStep(slideStep + 1);
		} else {
			verifyEmail1();
			setVerifying(true);
		}
	}

	return (
		<div className="h-full w-full">
			{!verifying && (
				<div className="flex flex-row w-full h-full place-content-center">
					<div className="v-slides-container">
						<div className="onboarding-v-slides" id="onboarding-v-slides">
							<Slide img={slide1} />
							<Slide img={slide2} />
							<Slide img={slide3} />
							<Slide img={slide4} />
							<Slide img={slide5} />
							<Slide img={slide6} />
							<Slide img={slide7} />
						</div>
					</div>
					<div className="w-6/12 h-full flex flex-col bg-white">
						<div className="h-slides-container w-full h-full">
							<div
								className="onboarding-h-slides flex flex-row"
								id="onboarding-h-slides">
								<TextSlide
									title={features[0].title}
									content={features[0].description}
								/>
								<TextSlide
									title={features[1].title}
									content={features[1].description}
								/>
								<TextSlide
									title={features[2].title}
									content={features[2].description}
								/>
								<TextSlide
									title={features[3].title}
									content={features[3].description}
								/>
								<TextSlide
									title={features[4].title}
									content={features[4].description}
								/>
								<TextSlide
									title={features[5].title}
									content={features[5].description}
								/>

								{/* <button
							className="comuna-primary-button mt-8"
							onClick={() => {
								verifyEmail1();
								setVerifying(true);
							}}>
							Next
						</button> */}
							</div>
						</div>
						<div
							className="controls w-full h-1/2 flex flex-row items-center justify-center"
							style={{
								paddingLeft: "10%",
								paddingRight: "10%",
							}}>
							<button
								onClick={carouselBack}
								className="comuna-transparent-button sm font-bold">
								Back
							</button>
							<CarouselIndicator
								steps={features.length}
								currentStep={slideStep}
							/>
							<button
								onClick={carouselForward}
								className="comuna-primary-button sm font-bold">
								Next
							</button>
						</div>
					</div>
				</div>
			)}

			{verifying && (
				<div className="flex flex-row w-full h-full place-content-center">
					<div
						className="w-full flex flex-col h-full text-center items-center justify-center bg-comuna-purple"
						style={{
							width: "100%",
							height: "100%",
							minWidth: "50%",
							minHeight: "50%",
						}}>
						<img
							className="fade-in"
							src={slide7}
							style={{
								width: "100%",
								height: "auto",
								minWidth: 400,
								minHeight: "100%",
							}}
							alt="people smiling and decorative colored shapes"
						/>
					</div>
					<div
						className="w-full h-full bg-white"
						style={{
							paddingTop: "20%",
							paddingLeft: "5%",
							paddingRight: "15%",
						}}>
						<h2 className="w-full">{strings.email_verification_title}</h2>
						<p className="mb-12 mt-8 w-full">
							{strings.email_verification_copy}
						</p>
						<p>Verification code</p>
						<input
							className="comuna-input"
							onChange={(e) => setVerifyCode(e.target.value)}
							type="text"
							placeholder="Code"
							id="code"
							name="code"
						/>

						{verifyError && (
							<p className="text-red-500 h-0">*The code is not valid</p>
						)}

						<button
							className="comuna-primary-button mt-8"
							onClick={() => {
								verifyEmail2();
							}}>
							{loading ? <Loader /> : "Verify code"}
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
