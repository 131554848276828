import { Modal } from "flowbite-react";
import React, { useState } from "react";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";

import { Loader } from "./loader";


interface ChallengeInvoiceModalProps {
	invoice: any;
	close: () => void;
}
const ChallengeInvoiceModal: React.FC<ChallengeInvoiceModalProps> = ({
	invoice,
	close,
}) => {


	const xIcon = require("../img/icons/x.png");

	const [loading, setLoading] = useState(false);
	const [challengeReason, setChallengeReason] = useState("");
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	const validateForm = ()=>{
		if(loading){
			return;
		}
		setError(false);
		if(challengeReason.length < 10){
			setError(true);
			return false;
		}
		else{
			ChallengeInvoice();
		}
	}

	async function ChallengeInvoice() {
		setLoading(true);
		var noIssues = true;

		var data = {
			id: invoice.id,
			update: "challenge",
			content: challengeReason,
			payment_method: "bank_transfer",
		};

		try {
			const token = await LocalStorageManager.getToken();
			const invoicesReq = await ComunaAPI.ChallengeInvoice(token, data);
			setSuccess(true);
		} catch (error) {
			noIssues = false;
			console.log(error);
		} finally {
			if (!noIssues) {
				alert("There was a problem with your request please tru again later");
			}
		}
	};

	
	if(success){
		return(
			<div className="text-center p-8">
					<b className="text-2xl font-bold mb-4">
						The invoice has been challenged
					</b>
					<p className="text-center font-medium text-sm mt-8">
						We have received your invoice challenge, and we will promptly review the case. Someone from MUNA will work to resolve this matter and
						may reach out to you for further details if needed.
					</p>
					<button
						onClick={()=>{
							close();
							window.location.reload();
						}}
						className="comuna-primary-button mt-6">
						ok
					</button>
				</div>
		)
	}
	
	return (
		<div className="flex flex-col w-full h-full bg-white rounded-lg py-5 px-5 relative text-center">
			
			<b className="text-2xl font-bold mb-4">Challenge the invoice</b>
			<p className="text-center font-medium text-sm">
				Please select a reason for challenging this invoice and provide
				additional details if needed. Your feedback helps us address any
				concerns and ensure accurate billing.
			</p>
			<label className="w-full text-sm my-1 mt-4 color-gray-5 font-medium text-left">Provide more details</label>
			<textarea
					rows={3}
					onChange={(e) => setChallengeReason(e.target.value)}
					className="comuna-input"
					placeholder="Provide mode details..."
			/>
			
			{error && <p className="w-full text-left text-red-500"> *Provide details on why you want to challenge this invoice </p>}

			<div className="flex flex-row w-full mt-6">
					<button
						onClick={close}
						className="comuna-secondary-button mr-4">
						Cancel
					</button>
					<button
						onClick={validateForm}
						className="comuna-primary-button">
						{loading? <Loader/>: "Challenge the invoice"}
					</button>
			</div>

		</div>
	);
};

export default ChallengeInvoiceModal;
