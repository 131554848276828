import { Spinner } from "flowbite-react";
import { useContext, useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useLoaderData, useRouteLoaderData, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";
import { BenefitItem } from "../components/BenefitItem";
import {
	formatStringDateFromServer,
} from "../utils/formaters";

import {
	fieldClassNamePurple,
} from "../utils/commonClassNames";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import PricingTable from "../components/PricingTable";

const linkedin_icon = require("../img/icons/linkedin-icon.png");
const web_icon = require("../img/icons/web-icon.png");
const hot_candidate_icon = require("../img/icons/hot_candidate.png");
const resume_icon = require("../img/icons/resume-icon.png");
const clock_icon = require("../img/icons/clock-icon.png");
const calendar_icon = require("../img/icons/calendar-icon-black.png");

export async function loader({ params }: any) {
	var hasLoaded = true;
	console.log("loading client proposal form", params.proposal_id);
	var token = await LocalStorageManager.getToken();

	const proposalFields = await ComunaAPI.getProposalFields(token);
	const organizationProfile = await ComunaAPI.GetOrganizationProfile(token);

	return { hasLoaded, proposalFields, organizationProfile };
}

export function ClientProposalForm() {

	const navigate = useNavigate();

	const { proposalFields, organizationProfile }: any = useLoaderData();

	//find the application to this resource request. 
	const { application_id, request_id }: any = useParams();
	const { resourceRequests, resReqFields }: any = useRouteLoaderData("hiring");

	const [selectedPlan, setSelectedPlan] = useState<string>("Essentials");
	const [selectedBenefits, setSelectedBenefits] = useState<Record<number, number>>({});

	const r = resourceRequests.filter((item: any) => item.id === parseInt(request_id));
	const request = r[0];
	const application = r[0].applications.filter((app: any) => app.id === parseInt(application_id))[0];

	const { token }: any = useContext(UserContext);

	const [paymentCadence, setPaymentCadence]: any = useState({
		value: "monthly",
		label: "Monthly",
	});
	const [salary, setSalary] = useState(0);
	const [salaryOk, setSalaryOk] = useState(true);

	const [contractLength, setContractLength] = useState(12);
	const [startDate, setStartDate] = useState(new Date());
	const [dateOk, setDateOk] = useState(true);

	const [spinning, setSpinning] = useState(false);

	const [benefits, setBenefits]: any = useState([]);
	const [payCadOptions, setPayCadOptions]: any = useState([]);
	const [note, setNote] = useState("");

	const [addingBenefits, setAddingBenefits] = useState(false);
	const [availableBenefits, setAvailableBenefits] = useState([]);
	const [initialized, setInitialized] = useState(false); //, setInitialized] = useState(false);

	const [proposalUpdated, setProposalUpdated] = useState(false);

	const plusIconPurple = require("../img/icons/plus-purple.png");
	const closeIcon = require("../img/icons/x.png");

	const panelRef = useRef<HTMLDivElement>(null);

	function handleClick(e: any) {
		if (panelRef.current && !panelRef.current.contains(e.target)) {
			navigate(-1);
		}
	}

	const initForm = async () => {
		if (application && !application.proposal) {
			setSalary(application.contractor.salary_expectation);
		}

		setAvailableBenefits(resReqFields.benefits);

		//initialize form values
		//PAYMENT CADENCE
		var paymentCadenceOptions = [];
		for (
			let index = 0;
			index < proposalFields.payment_cadence_options.length;
			index++
		) {
			if (organizationProfile.direct_contract && proposalFields.payment_cadence_options[index][0] !== "monthly") {
				continue;
			}
			paymentCadenceOptions.push({
				value: proposalFields.payment_cadence_options[index][0],
				label: proposalFields.payment_cadence_options[index][1],
			});
		}
		setPayCadOptions(paymentCadenceOptions);

	};

	useEffect(() => {
		if (initialized)
			return;

		initForm();

		var rrBenToOpt: any = [];
		var initial_benefits: any = null;
		if (application.proposal) {
			//PaymentCadence
			for (let subIndex = 0; subIndex < payCadOptions.length; subIndex++) {
				if (
					application.proposal.payment_cadence == payCadOptions[subIndex].value
				) {
					setPaymentCadence({
						value: application.proposal.payment_cadence,
						label: payCadOptions[subIndex].label,
					});
				}
			}

			//start date
			const proposalDate = new Date(application.proposal.start_date);
			setStartDate(proposalDate);

			//salary
			setSalary(application.proposal.yearly_salary);

			//contract length
			setContractLength(application.proposal.contract_length_months);

			initial_benefits = application.proposal.benefits;
		}
		//prefill benefits from resource request
		else if (request !== undefined) {
			initial_benefits = request.benefits;
		}
		else {
			return;
		}

		if (initial_benefits.length <= 0)
			return;

		const initialSelectedBenefits: Record<number, number> = {};
		setSelectedPlan(initial_benefits[0].plan_name);

		initial_benefits.forEach((benefit: any) => {
			rrBenToOpt.push(Number(benefit.id));

			if (resReqFields.benefits_plans) {
				const category = resReqFields.benefits_plans.find((plan: any) => plan.name === benefit.category_name);
				if (category) {
					const benefitInCategory = category.benefits.find((b: any) => b.name === benefit.name);
					if (benefitInCategory) {
						const planOptions = benefitInCategory.options.filter((opt: any) => opt[benefit.plan_name]);

						if (planOptions.length > 1) {
							const optionIndex = planOptions.findIndex((opt: any) => {
								return opt[benefit.plan_name] && opt[benefit.plan_name].description === benefit.description;
							});

							if (optionIndex !== -1) {
								initialSelectedBenefits[benefit.id] = optionIndex;
							}
						}
					}
				}
				setSelectedBenefits(initialSelectedBenefits);
			}
		});
		setBenefits(rrBenToOpt);

		setInitialized(true);
	}, []);

	function StateBadge(): any {
		if (application.state === 0) {
			return <div className="blue-badge big mr-2">Screening Candidate</div>;
		}
		if (application.state === 1) {
			return (
				<div className="orange-badge big mr-2">Cultural Fit Interview</div>
			);
		}
		if (application.state === 2) {
			return <div className="yellow-badge big mr-2">Technical Interview</div>;
		}
		if (application.state === 3) {
			return <div className="purple-badge big mr-2">Partner Interview</div>;
		}
		if (application.state === 4) {
			return <div className="blue-badge big mr-2">Offer Sent</div>;
		}
		if (application.state === 5) {
			return <div className="green-badge big mr-2">Hired</div>;
		}
		if (application.state === 6) {
			return <div className="red-badge big mr-2">Rejected</div>;
		}
	}

	function InterviewBadge() {
		//if hired
		const proposalSent = application.state >= 4;

		//if is past the cultural interview stage
		const canAskForInterview = application.state > 1;
		//was the interview requested?
		const interviewRequested =
			application.interview_available_dates != null ||
			application.interview_calendar_link != null;
		//is the interview scheduled?
		const interviewScheduled = application.interview_call_link != null;

		if (interviewScheduled) {
			return (
				<div className="blue-badge big flex flex-row mr-2 items-center">
					<img
						style={{
							width: 16,
							height: 16,
							bottom: 10,
							right: -10,
						}}
						src={clock_icon}
						alt="Fire icon"
						className="mr-1"
					/>
					Interview scheduled
				</div>
			);
		}

		if (interviewRequested) {
			return (
				<div className="blue-badge big flex flex-row mr-2 items-center">
					<img
						style={{
							width: 16,
							height: 16,
							bottom: 10,
							right: -10,
						}}
						src={clock_icon}
						alt="Fire icon"
						className="mr-1"
					/>
					Interview requested
				</div>
			);
		}

		return <></>;
	}

	const GetOneTimeBenefitsCost = () => {
		var total = 0;
		availableBenefits.map((benefit: any) => {
			if (benefits.includes(benefit.id) && benefit.one_time_only) {
				total += benefit.cost;
			}
		});
		return total;
	};

	const GetMonthlyBenefitsCost = () => {
		var total = 0;
		availableBenefits.map((benefit: any) => {
			if (benefits.includes(benefit.id) && !benefit.one_time_only) {
				total += benefit.cost;
			}
		});
		return total;
	};

	//this should be a dynamic percentage loaded from the backend?
	const GetPlatformFee = () => {
		const benefits = GetOneTimeBenefitsCost() + GetMonthlyBenefitsCost() * 12;
		const total = benefits + salary;
		let fee;
		
		if (organizationProfile.custom_comuna_fee === -1) {
			fee = total * 0.2 / 12;
		} else if (organizationProfile.fixed_comuna_fee) {
			fee = organizationProfile.custom_comuna_fee;
		} else {
			fee = total * (organizationProfile.custom_comuna_fee / 1200);
		}

		return fee;
	};

	//we are assuming a 2.5% fee of platform
	const GetGatewayFee = () => {
		const benefits = GetOneTimeBenefitsCost() + GetMonthlyBenefitsCost() * 12;
		const comuna_fee = GetPlatformFee();
		const total = benefits + salary + comuna_fee;
		var gateway_fee = total * 0.029 + 0.3;
		return gateway_fee;
	};

	//return the yearly contract cost.
	function GetTotalContractCost() {
		const benefits = GetOneTimeBenefitsCost() + GetMonthlyBenefitsCost() * 12;
		const comuna_fee = GetPlatformFee();
		const gateway_fee = GetGatewayFee(); //gateway fee is not gonna be show at this stage
		const total = benefits + salary + comuna_fee;
		return total;
	}

	function dateFormat(objectDate: Date) {
		let day = objectDate.getDate();
		let month = objectDate.getMonth();
		let year = objectDate.getFullYear();
		let formattedDate = year + "-" + (month + 1) + "-" + day;
		return formattedDate;
	}

	function dateFormatForFront(objectDate: Date) {
		let day = objectDate.getDate();
		let month = objectDate.getMonth();
		let year = objectDate.getFullYear();
		let formattedDate = year + "-" + (month + 1) + "-" + day;
		return formattedDate;
	}

	function GetFirstPaymentValue() {
		var salary_amount = salary / 12;
		var benefits_one_time = GetOneTimeBenefitsCost();
		var benefits_monthly = GetMonthlyBenefitsCost();

		if (paymentCadence === "bimonthly") {
			salary_amount = salary_amount / 2;
			benefits_monthly = benefits_monthly / 2;
		}

		var before_fees = salary_amount + benefits_one_time + benefits_monthly;
		var comuna_fee;
		if (organizationProfile.custom_comuna_fee === -1) {
			comuna_fee = before_fees * 0.2;
		} else if (organizationProfile.fixed_comuna_fee) {
			comuna_fee = organizationProfile.custom_comuna_fee;
		} else {
			comuna_fee = before_fees * (organizationProfile.custom_comuna_fee / 100);
		}
		var gateway_fee = (before_fees + comuna_fee) * 0.029 + 0.3; //gateway fee is not gonna be show at this stage
		var total = before_fees + comuna_fee;

		return total;
	}

	function GetSecondPaymentValue() {
		var salary_amount = salary / 12;
		var benefits_monthly = GetMonthlyBenefitsCost();

		if (paymentCadence === "bimonthly") {
			salary_amount = salary_amount / 2;
			benefits_monthly = benefits_monthly / 2;
		}

		var before_fees = salary_amount + benefits_monthly;
		var comuna_fee;
		if (organizationProfile.custom_comuna_fee === -1) {
			comuna_fee = before_fees * 0.2;
		} else if (organizationProfile.fixed_comuna_fee) {
			comuna_fee = organizationProfile.custom_comuna_fee;
		} else {
			comuna_fee = before_fees * (organizationProfile.custom_comuna_fee / 100);
		}
		var gateway_fee = (before_fees + comuna_fee) * 0.029 + 0.3; //Gateway fee is not gonna be show at this stage.
		var total = before_fees + comuna_fee;

		return total;
	}

	//returns an array with the three dates of the first three payments
	function GetPaymentDates() {
		//get the month and day of the start date
		const start_day = startDate.getDay();
		const start_month = startDate.getMonth();

		//by default the first payment will be the last of the month the contract starts in.
		var first_payment_date: any = new Date(
			startDate.getFullYear(),
			start_month,
			30
		);

		//if it is the second month (february) payment date is the 28
		if (start_month === 1) {
			first_payment_date = new Date(startDate.getFullYear(), start_month, 28);
		}

		//if it is a bimonthly contract then there are some special cases:
		if (paymentCadence.value === "bimonthly" && start_day < 15) {
			first_payment_date = new Date(startDate.getFullYear(), start_month, 15);
		}

		//second and third date depend on the first date and payment cadence:
		var second_payment_date: any = addDaysToDate(first_payment_date, 30);
		var third_payment_date: any = addDaysToDate(second_payment_date, 30);

		if (paymentCadence.value === "bimonthly") {
			second_payment_date = addDaysToDate(first_payment_date, 15);
			third_payment_date = addDaysToDate(second_payment_date, 15);
		}

		return [first_payment_date, second_payment_date, third_payment_date];
	}

	function addDaysToDate(date: any, daysToAdd: number) {
		const newDate = new Date(date);
		newDate.setDate(newDate.getDate() + daysToAdd);
		return newDate;
	}

	function changePaymentCadence(e: any) {
		setProposalUpdated(true);
		setPaymentCadence(e);
	}

	//in the past we had a function to check if the start date was at least two weeks from now
	//this business rule has been removed so we are no longer gonna check for it. The function is still here for reference.
	function checkStartDate() {
		var today: any = Date.now();
		var twoWeeksFromNow = addDaysToDate(today, 14);
		var isGreaterThanTwoWeeksFromNow = startDate > twoWeeksFromNow;

		setDateOk(isGreaterThanTwoWeeksFromNow);

		return isGreaterThanTwoWeeksFromNow;
	}

	function checkSalary() {
		var retVal = salary > 10000;
		setSalaryOk(retVal);
		return retVal;
	}

	function runChecks() {
		var sdOk = true;
		var sOk = checkSalary();
		return sdOk && sOk;
	}

	function changeBenefits(e: any) {
		setProposalUpdated(true);
		setBenefits(e);
	}

	function changeSalary(e: any) {
		setProposalUpdated(true);
		setSalary(Number(e.target.value));
	}

	function changeLength(e: any) {
		setProposalUpdated(true);
		setContractLength(Number(e.target.value));
	}

	function changeStartDate(e: any) {
		setProposalUpdated(true);
		setStartDate(e);
	}

	function changeNote(e: any) {
		setProposalUpdated(true);
		setNote(e.target.value);
	}

	async function sendProposal() {
		setSpinning(true);
		var tCheck = runChecks();

		if (!tCheck) {
			setSpinning(false);
			return;
		}

		var outBenefits = [];

		for (let index = 0; index < benefits.length; index++) {
			outBenefits.push(benefits[index].value);
		}

		var tDate = dateFormat(startDate);
		var newProposal = {
			payment_cadence: paymentCadence.value,
			contract_length_months: contractLength,
			start_date: tDate,
			yearly_salary: salary,
			benefits: benefits,
			role_name: request.role_name,
			role_description: request.position_overview,
			application: application.id,
		};

		var noIssues = true;

		try {
			const response = await ComunaAPI.CreateProposal(token, newProposal);
		} catch {
			setSpinning(false);
			noIssues = false;
		} finally {
			if (noIssues) {
				console.log("Created Proposal, cleanup!");
				setSpinning(false);
				success();
			}
		}
	}

	async function modifyProposal() {
		var tCheck = runChecks();

		if (!tCheck) {
			setSpinning(false);
			return;
		}

		var newProposal = {
			payment_cadence: paymentCadence.value,
			contract_length_months: contractLength,
			start_date: dateFormat(startDate),
			yearly_salary: salary,
			benefits: benefits,
			role_name: application.proposal.role_name,
			role_description: application.proposal.role_description,
		};

		var noIssues = true;

		try {
			const response = await ComunaAPI.ModifyProposal(
				token,
				application.proposal.id,
				newProposal
			);
		} catch {
			setSpinning(false);
			noIssues = false;
		} finally {
			if (noIssues) {
				console.log("Modified Proposal, cleanup!");
				setSpinning(false);
				success();
			}
		}
	}

	async function sendNote() {
		console.log("sending new note: " + note);
		if (note == "") {
			return;
		}
		var noIssues = true;

		var newPropUpdate = {
			update: "new_note",
			content: note,
		};
		console.log("sending:");
		console.log(newPropUpdate);

		try {
			const response = await ComunaAPI.UpdateProposal(
				token,
				application.proposal.id,
				newPropUpdate
			);
		} catch {
			noIssues = false;
		} finally {
			if (noIssues) {
				window.location.reload();
			}
		}
	}

	function deleteBenefit(benefitId: string) {
		const updatedArray = benefits.filter((benefit: any) => {
			return benefit !== benefitId;
		});
		changeBenefits(updatedArray);
	}

	function success() {
		// onClose();
		//should close or redirection
		window.location.reload();
	}

	if (addingBenefits) {
		return (
			<div
				id="comuna-popup "
				className={"comuna-popup open"}>
				<div className="w-3/4 min-h-full bg-white flex flex-col px-4 pb-8 rounded-xl mt-6 pt-6 relative">
					<PricingTable
						planSelected={selectedPlan}
						categories={resReqFields.benefits_plans}
						onPlanSelect={(plan: any, selectedBenefits: any) => {
							setSelectedPlan(plan);
							changeBenefits(selectedBenefits);
						}}
						initialSelectedBenefits={selectedBenefits}
					/>

					<div className="flex flex-row justify-end px-6 mt-4">
						<button
							className="comuna-primary-button sm"
							type="button"
							onClick={() => {
								setAddingBenefits(false);
							}}>
							Confirm Benefits
						</button>
					</div>
				</div>
			</div>
		);
	}

	const GoBack = () => {
		navigate(`/hiring/${request_id}/application/${application_id}`);
	}

	const GetHourlySalary = () => {
		// TODO
		// DIVIDED BY 2 BECAUSE OF RECENT FIX. REMOVE LATER
		const hourlySalary = application.salary_recommendation / 12 / 160 / 2;
		return Math.round(hourlySalary).toLocaleString();
	}

	return (
		<div
			id="comuna-popup "
			className={"comuna-popup open"}
			onClick={handleClick}>
			<div
				id=""
				className="pt-4 open bg-white comuna-card w-full"
				style={{ maxWidth: 1024 }}
				ref={panelRef}>
				<div
					className="flex flex-col w-full h-full relative"
					style={{ zIndex: 10000 }}>
					<div className="pt-2 px-12 flex flex-col h-full">
						<b className="text-3xl">Offer form</b>

						<div className="flex flex-row justify-between items-center bg-purple-light mb-3 rounded-lg p-4 mt-4">
							<div className="flex flex-row items-center">
								{/**Profile Picture */}
								<div className="relative">
									{application.contractor.profile_picture && (
										<img
											src={application.contractor.profile_picture}
											alt=""
											style={{ height: 140, width: 140, borderRadius: "50%" }}
										/>
									)}
									{!application.contractor.profile_picture && (
										<div
											className="bg-purple-light flex justify-center items-center font-bold comuna-purple text-4xl"
											style={{
												height: 84,
												width: 84,
												borderRadius: "50%",
												lineHeight: -10,
											}}>
											{application.contractor.first_name
												.charAt(0)
												.toUpperCase() +
												application.contractor.last_name
													.charAt(0)
													.toUpperCase()}
										</div>
									)}
								</div>

								{/**Name Title and badges */}
								<div className="flex flex-col ml-4 items-start w-fit">
									<h3
										style={{ height: 31 }}
										className="font-semibold black-text">
										{application.contractor.first_name +
											" " +
											application.contractor.last_name}
									</h3>
									<span className="mb-2">
										{application.contractor.title} -{" "}
										{application.contractor.country_location}
									</span>
									<div className="flex flex-row items-center w-fit">
										<StateBadge />
										<InterviewBadge />
										{application.contractor.top_candidate && (
											<div className="flex flex-row items-center yellow-badge big">
												<img
													style={{
														width: 16,
														height: 16,
														bottom: 10,
														right: -10,
													}}
													src={hot_candidate_icon}
													alt="Fire icon"
												/>
												Hot Candidate
											</div>
										)}
									</div>
								</div>
							</div>
							{/**Quick Actions Buttons */}
							<div className="flex flex-row items-start justify-end flex-1">
								{application.contractor.website && (
									<a
										className="comuna-circle-button"
										href={application.contractor.website}>
										<img
											src={web_icon}
											alt="Button to visit website of the candidate"
										/>
									</a>
								)}

								{application.contractor.linkedin && (
									<a
										className="comuna-circle-button"
										href={application.contractor.linkedin}>
										<img
											src={linkedin_icon}
											alt="Button to go to linkedin of the candidate"
										/>
									</a>
								)}

								{application.contractor.cv && (
									<a
										className="comuna-circle-button"
										href={application.contractor.cv}>
										<img src={resume_icon} alt="Button to download resume" />
									</a>
								)}
							</div>
						</div>

						<div className="flex flex-col h-full">
							<b className="mt-2 font-medium">Contract</b>

							{/*<div className="flex flex-row">
								<div className={fieldClassNamePurple + " mr-4"}>
									<span className="color-gray-5">Length</span>
									<b>1 year</b>
								</div>

								<div className={fieldClassNamePurple}>
									<span className="color-gray-5">Hours</span>
									<b>160h / month</b>
								</div>
								</div>*/}

							<div className="flex flex-row">
								<div
									className={
										fieldClassNamePurple +
										" mr-4 relative proposal-input-container"
									}>
									<div className="flex flex-row w-full">
										<span className="color-gray-5">Start date</span>
										{/* <img
										style={{
											width: 20,
											height: 20,
										}}
										src={calendar_icon}
										alt="Calendar icon"
										className="mr-1"
									/> */}
									</div>
									<ReactDatePicker
										dateFormat="MMMM d, yyyy"
										className="rounded-lg text-xs block w-28 pl-4 p-2.5 mr-2 border-2 border-gray-200"
										selected={startDate}
										onChange={changeStartDate}
									/>
								</div>

								{!organizationProfile.direct_contract &&
									<div className={fieldClassNamePurple}>
										<span className="color-gray-5">Hours</span>
										<b>160h / month</b>
									</div>
								}

								{/* <div className={fieldClassNamePurple}>
									<span className="color-gray-5">End Date</span>
									<b>
										{
											//start date + a year
											formatDateFromString(
												addDaysToDate(startDate, 365).toString()
											)
										}
									</b>
									</div> */}
							</div>

							<div className="flex flex-row mb-2">
								<div
									className={
										fieldClassNamePurple +
										" mr-4 relative proposal-input-container"
									}>
									{" "}
									<div className="flex flex-col">
										<b className=" font-normal">Payment</b>
										<b className=" font-normal">cadence*</b>
									</div>
									<Select
										className="comuna-select text-sm p-0"
										defaultValue={payCadOptions}
										options={payCadOptions}
										value={paymentCadence}
										onChange={changePaymentCadence}
										classNames={{
											menuList: (state) => "bg-white",
										}}
									/>
								</div>

								<div
									className={
										fieldClassNamePurple + " relative proposal-input-container"
									}>
									{" "}
									<b className="mt-2 font-normal">{organizationProfile.direct_contract ? "Hourly Salary" : "Candidate Salary"}</b>
									{/* <EditableField
									label="Candidate salary"
									value={
										salary !== undefined
											? salary
											: application.contractor.salary_expectation
									}
									onChange={changeSalary}
									edit={true}
									horizontal={true}
								/> */}

									{organizationProfile.direct_contract ? (
										<b>
											<span className="color-gray-5">$</span>{" "}
											{GetHourlySalary()}
										</b>
									) : (
										<input
											onChange={changeSalary}
											type="number"
											className="rounded-xl text-sm block w-28 pl-4 p-2.5 border-2 border-gray-200"
											value={
												salary !== undefined
													? salary
													: application.contractor.salary_expectation
											}
											required
										/>
									)}
								</div>
							</div>

							<label className="mt-2 font-sm">
								*If you edit the salary to a lower price you'll have to start
								negotiation with the candidate
							</label>

							<b className="mt-2 mb-2 font-medium">Benefits</b>
							<div className="flex flex-row h-fit w-full flex-wrap">
								{benefits.map((benef: any, i: number) => {
									return (
										<div key={i}>
											{availableBenefits.map((aBen: any, j: number) => {
												return (
													aBen.id === benef && (
														<BenefitItem
															key={j}
															benefit={aBen}
															onDelete={deleteBenefit}
															canDelete={true}
															color={1}
														/>
													)
												);
											})}
										</div>
									);
								})}
								<button
									className="comuna-secondary-button xs"
									onClick={() => {
										setAddingBenefits(true);
									}}>
									<img
										src={plusIconPurple}
										alt="plus icon purple"
										style={{ width: 20, marginRight: 7 }}
									/>
									<span className="text-xs">Add more</span>
								</button>
							</div>

							<b className="mt-2 mb-2 font-medium">Cost Summary</b>
							<div className="bg-purple-light p-4 rounded-xl">

								{organizationProfile.direct_contract ? (

									<div className="flex flex-row justify-between">
										<span className="color-gray-5 font-medium">
											Hourly Salary*
										</span>
										<b>
											{" "}
											<span className="color-gray-5">$</span>{" "}
											{GetHourlySalary()}
										</b>
									</div>

								) : (
									<div className="flex flex-row justify-between">
										<span className="color-gray-5 font-medium">
											Yearly Salary*
										</span>
										<b>
											{" "}
											<span className="color-gray-5">$</span>{" "}
											{salary.toLocaleString()}
										</b>
									</div>
								)}


								<div className="flex flex-row justify-between">
									<span className="color-gray-5 font-medium">
										One time benefits
									</span>
									<b>
										{" "}
										<span className="color-gray-5">$</span>{" "}
										{
											GetOneTimeBenefitsCost()
												.toLocaleString("En-US")
												.split(".")[0]
										}
									</b>
								</div>

								{!organizationProfile.direct_contract &&
									<>
										<div className="flex flex-row justify-between">
											<span className="color-gray-5 font-medium">
												Monthly benefits
											</span>
											<b>
												{" "}
												<span className="color-gray-5">$</span>{" "}
												{
													GetMonthlyBenefitsCost()
														.toLocaleString("En-US")
														.split(".")[0]
												}{" "}
												/ Month
											</b>
										</div>

										<div className="flex flex-row justify-between">
											<span className="color-gray-5 font-medium">
												MUNA Admin Costs (
												{organizationProfile.custom_comuna_fee === -1
													? "20% "
													: organizationProfile.fixed_comuna_fee
														? `$${organizationProfile.custom_comuna_fee} `
														: `${organizationProfile.custom_comuna_fee}% `
												}
												on top of monthly salary)
											</span>
											<b>
												{" "}
												<span className="color-gray-5">$</span>{" "}
												{
													(GetPlatformFee())
														.toLocaleString("En-US")
														.split(".")[0]
												}
											</b>
										</div>
									</>
								}

								{/* <div className="flex flex-row justify-between">
					<span className="color-gray-5 font-medium">Payment gateway fee</span>
					<b> <span className="color-gray-5">$</span> {GetGatewayFee().toLocaleString("En-US").split(".")[0]}</b>
			</div> */}
							</div>

							<b className="mt-4 mb-2 font-medium">First 3 payments</b>
							<div className="flex flex-row">
								<div className={fieldClassNamePurple + " font-medium"}>
									<span>
										{formatStringDateFromServer(GetPaymentDates()[0])}
									</span>
									<b>
										<span className="color-gray-5">$</span>
										{
											GetFirstPaymentValue()
												.toLocaleString("En-US")
												.split(".")[0]
										}
									</b>
								</div>
								<div className={fieldClassNamePurple + " mx-4 font-medium"}>
									<span>
										{formatStringDateFromServer(GetPaymentDates()[1])}
									</span>
									<b>
										<span className="color-gray-5">$</span>
										{
											GetSecondPaymentValue()
												.toLocaleString("En-US")
												.split(".")[0]
										}
									</b>
								</div>
								<div className={fieldClassNamePurple + " font-medium"}>
									<span>
										{formatStringDateFromServer(GetPaymentDates()[2])}
									</span>
									<b>
										<span className="color-gray-5">$</span>
										{
											GetSecondPaymentValue()
												.toLocaleString("En-US")
												.split(".")[0]
										}
									</b>
								</div>
							</div>
						</div>

						{!dateOk && (
							<p className="text-red-500 px-12">
								*The start date of the contract has to be at least two weeks
								from now.
							</p>
						)}
						{!salaryOk && (
							<p className="text-red-500 px-12">
								*The salary has to be greater than $10,000
							</p>
						)}
					</div>

					{/* Bottom Row */}
					<div className="flex flex-row bg-purple-light px-12 pt-6 pb-8 rounded-b-2xl mt-2 items-end">
						<div className="flex flex-col w-full">
							{
								!organizationProfile.direct_contract &&
								<>
									<span className="color-gray-5">Total contract cost</span>
									<b className="text-3xl">
										<span className="color-gray-4">$</span>
										<span className="">
											{GetTotalContractCost().toLocaleString("En-US").split(".")[0]}
										</span>
									</b>
								</>
							}
						</div>

						<button
							className="comuna-secondary-button sm whitespace-nowrap mr-2"
							onClick={() => {
								window.history.back();
							}}>
							Go Back
						</button>

						{application.proposal === null && (
							<button
								onClick={sendProposal}
								className="comuna-primary-button sm whitespace-nowrap">
								{spinning ? (
									<Spinner color={"white"}></Spinner>
								) : (organizationProfile.direct_contract ? "Accept candidate" : "Submit offer")}
							</button>
						)}

						{application.proposal !== null && (
							<button
								disabled={!proposalUpdated}
								onClick={modifyProposal}
								className={
									proposalUpdated
										? "comuna-orange-dark-button sm whitespace-nowrap"
										: "comuna-disabled-button sm whitespace-nowrap"
								}>
								{spinning ? (
									<Spinner color={"white"}></Spinner>
								) : (
									"Update offer"
								)}
							</button>
						)}
					</div>

					<button
						onClick={GoBack}
						style={{
							position: "absolute",
							top: 12,
							right: 22,
							width: 30,
							height: 30,
						}}>
						<img src={closeIcon} alt="X icon" />
					</button>
				</div>
			</div>
		</div>
	);
}
