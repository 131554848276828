import { useState } from "react";
import { createPortal } from "react-dom";
import { useRouteLoaderData, Link, useLoaderData } from "react-router-dom";

import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { formatDateToLongString } from "../utils/formaters";

const xIcon = require("../img/icons/x.png");

export async function loader() {
  var token = await LocalStorageManager.getToken();
  var contracts = await ComunaAPI.GetUserContracts(token);

  if (contracts.length > 0) {
    const contract_signed = true;
    const legal_contract = contracts[0];
    return { contract_signed, legal_contract };
  }
}

export function ContractorContractDetail() {
  const { contract_signed, legal_contract }: any = useLoaderData();
  const { contracts }: any = useRouteLoaderData("contractor_root");
  const [contract, setContract] = useState<any>(
    contracts.length > 0 ? contracts[0] : null
  );

  function PrintContract() {
    const divToPrint = document.getElementById("contract_text");
    if (divToPrint !== null) {
      const divContents = divToPrint.innerHTML;

      // Open a new window
      const printWindow = window.open("", "", "height=1000,width=1000");

      // Check if the new window was successfully opened
      if (printWindow === null) {
        alert(
          "Unable to open the print window. Please check your browser settings and allow pop-ups for this site."
        );
        return;
      }

      // Write the content to the new window
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Contract</title>
            <style>
              /* Global Styles */
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              /* Signature Section */
              .signature-section {
                margin-top: 2rem;
              }
              .signature-block {
                text-align: left;
              }
              .signature-block .signature {
                font-style: italic;
                font-family: cursive;
                font-size: 2.25rem;
                text-decoration: underline;
                margin-bottom: 0.25rem;
                white-space: nowrap; /* Prevent wrapping */
              }
              .mt-2 {
                margin-top: 0.5rem;
              }
              .underline {
                text-decoration: underline;
              }
              .italic {
                font-style: italic;
              }
              /* Contract Signed Message */
              .contract-signed-message {
                margin-top: 2rem;
                color: #4a5568;
                font-size: 1rem;
              }
              b {
                font-weight: bold;
              }
            </style>
          </head>
          <body>
            ${divContents}
          </body>
        </html>
      `);
      printWindow.document.close();

      // Ensure the content is fully loaded before printing
      printWindow.onload = function () {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      };
    } else {
      alert("Contract content not found.");
    }
  }

  if (contract === null) {
    return null;
  }

  return createPortal(
    <div className="comuna-popup open">
      <div
        className="comuna-card p-6 py-10 w-full relative fade-in"
        style={{ maxWidth: 1024 }}
      >
        {/* Close Button */}
        <Link
          to={"/dashboard"}
          className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5"
        >
          <img src={xIcon} alt="icon of an x" />
        </Link>

        <h1 className="black-text text-3xl text-center font-bold mb-6">
          My Contract
        </h1>
        <div className="bg-purple-light p-6 rounded-xl">
          <div className="flex flex-row mb-6 items-center">
            <div className="flex flex-row flex-1">
              <h2 className="text-2xl p-0">{contract.organization.name}</h2>
              <span className="active-badge ml-4">Active</span>
            </div>
            <button
              onClick={PrintContract}
              className="comuna-secondary-button font-medium sm"
            >
              Download contract
            </button>
          </div>

          <div
            id={"contract_text"}
            style={{
              padding: "30px 80px",
            }}
            className="bg-white rounded-xl black-text"
          >
            <div
              dangerouslySetInnerHTML={{ __html: contract.contract }}
            ></div>
            {contract_signed && (
              <>
                <div className="px-4 signature-section mt-4">
                  {/* User's Signature */}
                  <div className="signature-block">
                    <p className="italic font-[cursive] text-4xl signature underline">
                      {legal_contract.signature}
                    </p>
                    <p className="mt-2">{legal_contract.client_name + " - Contractor Signature"}</p>
                  </div>
                </div>
                <div className="px-4">
                  {/* Contract Signed On Message */}
                  <div className="mt-8 text-gray-700 contract-signed-message">
                    Contract signed on{" "}
                    <b>
                      {formatDateToLongString(legal_contract.date_signed)}
                    </b>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
}