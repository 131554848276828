import { Modal, Select, Table } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
import { useLoaderData, useParams } from "react-router";
import ChallengeInvoiceModal from "../components/ChallengeInvoiceModal";
import ClientInvoiceItem from "../components/ClientInvoiceItem";
import InvoiceDetailView from "../components/InvoiceDetailView";
import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { UserContext } from "../Providers/UserContext";
import { ComunaPanel } from "../components/ComunaPanel";
import { formatDateToLongString } from "../utils/formaters";

export async function loader({ params }: any) {
	var token = await LocalStorageManager.getToken();
	const invoices = await ComunaAPI.GetInvoices(token);
	return { token, invoices };
}

export default function ClientInvoices() {
	const { user, setBusy }: any = useContext(UserContext);
	const xIcon = require("../img/icons/x.png");

	const { token, invoices }: any = useLoaderData();
	const { invoice_id } = useParams();

	const [detailView, setDetailView] = useState(false);
	const [detailedInvoice, setDetailedInvoice] = useState<any>({});

	const [payView, setPayView] = useState(false);
	const [challengeView, setChallengeView] = useState(false);

	const [hasBeenChallenged, setHasBeenChallenged] = useState(false);

	const [paymentMethod, setPaymentMethod] = useState("bank_transfer");

	useEffect(() => {
		//rerouting logic according to role
		if (user.role === "hiring_manager" || user.role === "interviewer") {
			window.location.href = "/hiring";
		}
		console.log("detail view invoice from url " + invoice_id);
		console.log(invoices);
		if (invoice_id !== undefined) {
			invoices.forEach((invoice: any) => {
				if (invoice.id + "" === invoice_id) {
					console.log("found invoice " + invoice_id);
					setDetailedInvoice(invoice);
					setDetailView(true);
				}
			});
		} else {
			console.log("no invoice id");
		}

		setBusy(false);
	}, [invoice_id]);

	function showPayView() {}

	function showChallengeView() {
		setChallengeView(true);
	}

	const payInvoice = async (invoiceId: string) => {
		var noIssues = true;

		var data = {
			id: invoiceId,
			update: "pay",
			payment_method: paymentMethod,
		};

		try {
			const invoicesReq = await ComunaAPI.PayInvoice(token, data);
			window.location.replace(invoicesReq.url);
		} catch (error) {
			noIssues = false;
			console.log(error);
			alert("There was an error with your request, please try again later.");
		} finally {
			if (noIssues) {
				console.log("no issues");
			}
		}
	};

	const challengeInvoice = async (
		invoiceId: string,
		challengeReason: string
	) => {
		var noIssues = true;

		var data = {
			id: invoiceId,
			update: "challenge",
			content: challengeReason,
		};

		try {
			const invoicesReq = await ComunaAPI.ChallengeInvoice(token, data);
			console.log(invoicesReq);
			// setInvoices(invoicesReq);
			setHasBeenChallenged(true);
		} catch (error) {
			noIssues = false;
			console.log(error);
		} finally {
			if (noIssues) {
				console.log("no issues");
			}
		}
	};

	const itemPayInvoice = async (id: string) => {
		console.log("starting payment, invoice " + id);
		setPayView(true);

		// payInvoice(id);
	};

	const closeChallengeView = () => {
		setChallengeView(false);
	};

	const challengeReason = (reason: string) => {
		console.log("challenge reason: " + reason);
		challengeInvoice(detailedInvoice.id, reason);
	};

	const closeHasBeenChallengedView = () => {
		setHasBeenChallenged(false);
		closeChallengeView();
		window.location.reload();
	};

	const total = ()=>{
		return (
			detailedInvoice.total_salary +
			detailedInvoice.total_benefits +
			detailedInvoice.one_time_total +
			detailedInvoice.comuna_fee +
			detailedInvoice.payment_gateway
		);
	}



	const TotalPlusFee = ()=>{
		if(paymentMethod==="bank_transfer") {
			return total();
		}
		return (Math.ceil(total()+0.3) / 0.97);
	}


	if (invoices.length === 0) {
		return (
			<div className="flex flex-col w-full h-full px-20 py-10">
				<b className="w-full text-3xl font-extrabold comuna-purple mb-8">
					My invoices
				</b>
				<div
					className="bg-white p-6 rounded-xl flex justify-center items-center"
					style={{ minHeight: 420 }}>
					<p>
						Once you have an active contractor you will find your invoices and
						payments here.
					</p>
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col w-full h-full px-10 py-10 bg-comuna-purple-light-alpha">
			<b className="w-full text-3xl font-extrabold mb-8">My invoices</b>

			<div className="bg-white p-6 rounded-xl">
				<b style={{ color: "#5D6979" }}>Total of {invoices.length} invoices</b>

				<table className="mt-2 w-full invoice-table" style={{ marginTop: -24 }}>
					<tr
						style={{
							position: "relative",
							top: 20,
						}}>
						<th className="table-head text-left pl-2">Period</th>
						<th className="table-head text-left">Pay on</th>
						<th className="table-head text-left">Paid</th>
						<th className="table-head text-left">Amount</th>
						<th className="table-head text-right pr-4">Status</th>
					</tr>

					{invoices.map((subInvoice: any, index: number) => {
						return (
							<ClientInvoiceItem
								onClick={() => {
									window.location.href = "/invoices/" + subInvoice.id;
									// SelectItem(subInvoice);
								}}
								invoice={subInvoice}
								key={index}
							/>
						);
					})}
				</table>
			</div>


			{detailView &&
			<ComunaPanel max_width={1224} prevent_close_on_click_outside={true} onClose={()=>{setDetailView(false)}}>
				<InvoiceDetailView
					invoice={detailedInvoice}
					close={() => {
						setDetailView(false);
					}}
				/>
			</ComunaPanel>}

		</div>
	);
}
