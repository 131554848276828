import { useContext, useEffect, useRef, useState } from "react";
import { ComunaAPI } from "../managers/ComunaAPI";
import { formatTimestamp } from "../utils/formaters";
import {
	primaryButtonClassName,
	secondaryButtonClassName,
	cancelButtonClassName,
	disabledButtonClassName,
} from "../utils/commonClassNames";
import { UserContext } from "../Providers/UserContext";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { useLoaderData } from "react-router-dom";
import { ProposalFromServer } from "../types";
import { debug } from "console";

interface MessageComponentProps {
	proposal?: any;
	application?: any;
}

const MessagesComponent: React.FC<MessageComponentProps> = ({
	application = null,
	proposal = null,
}) => {
	const token = LocalStorageManager.getToken();
	const { user }: any = useContext(UserContext);
	const noteScrollContainer = useRef<HTMLDivElement>(null);

	const [notes, setNotes] = useState(application ? [...application.notes, ...application.notes_proposal]: [...proposal.notes]);

	const [newMessage, setNewMessage] = useState<string>();

	const sendIcon = require("../img/icons/sendMsgIcon.png");

	const [email, setEmail] = useState(
		user.profile_type === "client" ? user.username : user.email
	);

	useEffect(() => {
		ScrollNotesToTheBottom();
		updateNotes();
		const interval = setInterval(() => {
			updateNotes();
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	async function updateNotes() {
		
		if(user.profile_type === "client" && application){
			var token = await LocalStorageManager.getToken();
			const updatedApp = await ComunaAPI.GetApplication(token, application.id);
			setNotes([...updatedApp.notes, ...updatedApp.notes_proposal]);
			ScrollNotesToTheBottom();
		}

		if(user.profile_type === "contractor" && proposal){
			var token = await LocalStorageManager.getToken();
			const updatedProposal = await ComunaAPI.GetUserProposals(token);
			if(updatedProposal){
				//get proposal with id 
				const p = updatedProposal.filter((p: ProposalFromServer) => p.id === proposal.id)[0];
				setNotes(p.notes);
				ScrollNotesToTheBottom();
			}
		}

	}

	const ScrollNotesToTheBottom = () => {
		window.setTimeout(() => {
			if (noteScrollContainer.current) {
				noteScrollContainer.current.scrollTop =
					noteScrollContainer.current.scrollHeight;
			}
		}, 250);
	};

	const SendMessage = async () => {
		try {
			if (newMessage !== "") {
				
				if(user.profile_type === "client" && application){
					await ComunaAPI.UpdateApplication(token, application.id, { update: "note", content: newMessage,});
					setNewMessage("");
				}
				
				if(user.profile_type === "contractor" && proposal){
					await ComunaAPI.UpdateProposal(token, proposal.id, { update: "note", content: newMessage,});
					setNewMessage("");
				}
			}
		} catch (error) {
			alert("There was an error sending your message, please try again later");
		} finally {
			await updateNotes();
			ScrollNotesToTheBottom();
		}
	};

	return (
		<div className="flex flex-col w-full h-full rounded-b-2xl p-3" >
			<div
				ref={noteScrollContainer}
				className="bg-white flex flex-col" style={{flex:"1 1 1px", overflowY:"auto"}} >
				{notes !== undefined &&
					notes.map((note: any, i: number) => {
						return (
							<>
								{note !== undefined && (
									<div
										key={i}
										className={
											"flex flex-col mb-4 " +
											(note.creator === email ? "items-end" : "items-start")
										}>
										<div className="flex flex-row">
											<span className="font-bold">
												{note.creator +
													(note.creator_profile === "comuna_staff"
														? " from MUNA"
														: " ")}
											</span>
											<span className="text-xs ml-2 mt-1 text-gray-600">
												{formatTimestamp(note.date_created)}
											</span>
										</div>
										<p
											className={
												"  p-3 mt-2 " +
												(note.creator === email
													? "rounded-l-xl rounded-br-xl text-white bg-comuna-purple"
													: "rounded-r-xl rounded-bl-xl text-black bg-comuna-purple-light-alpha")
											}>
											{note.content}
										</p>
									</div>
								)}
							</>
						);
					})}
			</div>
			<div className="flex flex-row mt-5 items-end">
				<input
					value={newMessage}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setNewMessage(e.target.value)
					}
					onKeyDown={(e) => {
						//if enter send  message
						if (e.key === "Enter") {
							SendMessage();
						}
					}}
					className="w-full h-12 bg-white mr-2 rounded-xl bg-comuna-purple-light-alpha p-4"
					placeholder="Send a message to the candidate and the MUNA Staff"
					type="text"
				/>
				<img src={sendIcon} onClick={SendMessage}></img>
			</div>
		</div>
	);
};

export default MessagesComponent;
