import React, { useContext, useEffect, useState } from "react";
import { ComunaAPI } from "../managers/ComunaAPI";
import { useSearchParams } from "react-router-dom";
import { Loader } from "../components/loader";
import { UserContext } from "../Providers/UserContext";
import { FormatDateToLocalString } from "../utils/formaters";
import timezones from "../timezones.json";
import { Radio } from "flowbite-react";
import { CompanyProfilePicture } from "../components/CompanyProfilePicture";
import ContractorStepTracker from "../components/ContractorStepTracker";
import OrgAboutView from "../components/OrgAboutView";
import { relative } from "path";

const countryCodeOptions = [{ value: "Select", label: "Select" }];

for (let index = 0; index < timezones.length; index++) {
	const element = {
		value: timezones[index].text,
		label: timezones[index].text,
	};
	countryCodeOptions.push(element);
}

const ContractorInterviewConfirmation: React.FC = () => {
	const { token, user }: any = useContext(UserContext);

	const [datesInfo, setDatesInfo]: any = useState(null);
	const [selectedDate, setSelectedDate] = useState<string>("");
	const [interviewDates, setInterviewDates] = useState<string[]>([]);
	const [loadingDates, setLoadingDates] = useState<boolean>(false);
	const [sendingRequest, setSendingRequest] = useState<boolean>(false);
	const [reqSuccess, setReqSuccess] = useState<string>("");
	const [searchParams, setSearchParams] = useSearchParams();
	const interviewId = searchParams.get("interviewId");

	const graphic_1 = require("../img/graphics/graphic_1.jpg");
	const comuna_logo = require("../img/muna-logo-green.png");
	const calendarGraphic = require("../img/graphics/calendarGraphic.png");
	const laptopGuyGraphic = require("../img/graphics/contractor_proposal_img1.png");

	const clock_image = require("../img/graphics/clock_graphic.png");
	const topBg = require("../img/graphics/topBg.png");
	const leftArrow = require("../img/icons/left_arrow_black.png");

	const [confirming, setConfirming] = useState<boolean>(false);

	const primaryButton =
		"rounded-lg bg-comuna-purple-transparent items-start px-4 py-2  ";
	const secondaryButton =
		"rounded-lg bg-comuna-purple-light-alpha items-start px-4 py-2 ";

	const handleGetInterviewDates = async (uuid: any) => {
		setLoadingDates(true);
		try {
			const dates = await ComunaAPI.getInterviewDates(uuid);
			console.log("dates: ", dates);
			setDatesInfo(dates);
			const initialSelectedDate =
				dates.interview_available_dates.split(/[,]\w/gi)[0];
			setInterviewDates(
				dates.interview_available_dates
					.split(",")
					.filter((date: string) => date.trim() !== "")
			);
			setLoadingDates(false);

			if (dates.accepted_date != null) {
				if (dates.accepted_date === "rejected") {
					setReqSuccess("reject");
				} else {
					setSelectedDate(dates.accepted_date);
					setReqSuccess("accept");
				}
			}
		} catch (error) {
			setLoadingDates(false);
			setInterviewDates(["error"]);
			console.error("Error fetching interview dates:", error);
		}
	};

	const CompanyName = () => {
		if (datesInfo != null && datesInfo.company_profile != null) {
			console.log(datesInfo.company_profile);
			return datesInfo.company_profile.name;
		} else {
			return "Company";
		}
	};

	const CompanyLocation = () => {
		if (datesInfo != null && datesInfo.company_profile != null) {
			console.log(datesInfo.company_profile);
			return (
				datesInfo.company_profile.country +
				", " +
				datesInfo.company_profile.city
			);
		} else {
			return "Company";
		}
	};

	const handleResponseInterview = async (
		uuid: any,
		action: "accept" | "reject"
	) => {
		try {
			if (action === "accept" && selectedDate === "") {
				alert("Please select a date");
				return;
			}

			if (action === "accept") {
				const dateObject = new Date(selectedDate);
				const formattedDate = `${dateObject.getFullYear()}-${(
					dateObject.getMonth() + 1
				)
					.toString()
					.padStart(2, "0")}-${dateObject
					.getDate()
					.toString()
					.padStart(2, "0")}T${dateObject
					.getHours()
					.toString()
					.padStart(2, "0")}:${dateObject
					.getMinutes()
					.toString()
					.padStart(2, "0")}:${dateObject
					.getSeconds()
					.toString()
					.padStart(2, "0")}`;
				setSendingRequest(true);
				const response = await ComunaAPI.answerInterview(
					uuid,
					action,
					formattedDate
				);
			}

			if (action === "reject") {
				const formattedDate = "2023-11-17 20:56:47";
				setSendingRequest(true);
				const response = await ComunaAPI.answerInterview(
					uuid,
					action,
					formattedDate
				);
			}
			setConfirming(false);

			setSendingRequest(false);
			setReqSuccess(action);
		} catch (error) {
			setSendingRequest(false);
			setReqSuccess("error");
			console.error("Error answering interview:", error);
		}
	};

	useEffect(() => {
		handleGetInterviewDates(interviewId);
		console.log("countryCodeOptions", countryCodeOptions);
	}, [interviewId]);

	function backToSite() {
		window.location.href = "/";
	}

	return (
		<div id="login-view bg-comuna-purple-light-alpha" className="h-full">
			<img
				src={topBg}
				className=""
				style={{ height: "250px", width: "100%" }}
			/>
			<img
				src={comuna_logo}
				className="pl-3 pt-3 -mt-60"
				style={{ width: "130px" }}
			/>

			<div className="flex flex-col w-full h-full px-24 pt-10 bg-transparent">
				<label className="font-bold text-white text-2xl pb-8">
					Schedule an interview with{" "}
					{datesInfo !== null &&
						datesInfo.company_profile !== null &&
						datesInfo.company_profile !== undefined &&
						datesInfo.company_profile.name}
					!
				</label>
				<div className="w-full h-fit bg-white rounded-xl">
					{datesInfo !== null && datesInfo.company_profile !== null && (
						<CompanyProfilePicture
							company={datesInfo.company_profile}
							actionButton="Check Role Description"
							action={() => {
								// setCurrentPage("description");
							}}
						/>
					)}
				</div>
				<div className="flex flex-row w-full h-fit">
					<div className="flex flex-col w-full h-fit bg-white rounded-xl mt-4 mr-2 pb-2">
						<div className="w-full h-fit">
							<div className="w-full h-fit">
								<div className="w-full h-full flex flex-col  px-12 sm:px-5 sm:py-5">
									{/* * Success Confirmed */}
									{confirming && (
										<div className="flex flex-col w-full">
											<img
												src={calendarGraphic}
												className="w-4/12 mb-2 self-center"
											/>
											<b className="w-full text-2xl font-extrabold">
												You're scheduling an interview with {CompanyName()} for:
											</b>
											<div className="flex flex-row rounded-lg bg-comuna-purple-transparent px-6 py-2 mt-12">
												<b className="w-full comuna-purple font-semibold text-center text-2xl">
													{FormatDateToLocalString(selectedDate)}
												</b>
											</div>

											{/* <div className="w-full h-fit flex flex-row"> */}
											<div className="w-full flex flex-row items-end justify-between">
												{/* <button
													className="comuna-secondary-button sm mt-10"
													onClick={() => {
														setConfirming(false);
													}}>
													Go back
												</button> */}
												<div className="w-6/12">
													<img
														onClick={() => {
															setConfirming(false);
														}}
														src={leftArrow}
														style={{ width: "40px", height: "40px" }}
														className="cursor-pointer"
													/>
												</div>
												<button
													className="comuna-primary-button sm ml-6 mt-10 self-end"
													onClick={() => {
														handleResponseInterview(interviewId, "accept");
													}}>
													Confirm and schedule
												</button>
											</div>
										</div>
									)}
									{/** Interview Rejected */}
									{reqSuccess === "reject" && (
										<div>
											<b className="w-full text-3xl font-extrabold comuna-purple">
												Interview Rejected
											</b>
											<p className="my-4">
												Thank you for answering, someone from the MUNA team
												will be in touch soon to find a time that works for you.
											</p>
											<p>You can close this page.</p>
										</div>
									)}
									{/** Interview Form */}
									{reqSuccess === "" && !confirming && (
										<div className="flex flex-col w-full">
											<img
												src={calendarGraphic}
												className="w-4/12 mb-2 self-center"
											/>
											<b className="w-full text-2xl font-extrabold mb-12">
												Choose a date for your interview with {CompanyName()}
											</b>

											{!loadingDates && interviewDates.length > 0 ? (
												interviewDates[0] === "error" ? (
													<p className="p-2 rounded bg-red-100">
														Error Loading Dates
													</p>
												) : (
													<ul className="w-full flex flex-col gap-2">
														{interviewDates.map((date, i) => (
															<li
																onClick={() => setSelectedDate(date)}
																key={i}
																className={
																	`${
																		selectedDate === date
																			? primaryButton
																			: secondaryButton
																	} transition-all ${
																		reqSuccess
																			? "cursor-default pointer-events-none"
																			: "cursor-pointer"
																	}` + " flex flex-row items-center"
																}>
																{/* <Radio
														id={date + i}
														name="interviewDates"
														value={date + i}
														className="w-fit mr-4"
													/> */}
																{selectedDate === date && (
																	<div className="rounded-full border-2 border-comuna-purple w-fit h-fit p-1 mr-2 items-center">
																		<div className="rounded-full bg-comuna-purple w-fit h-fit p-1" />
																	</div>
																)}
																{selectedDate !== date && (
																	<div className="rounded-full border-2 border-gray-300 w-fit h-fit p-1 mr-2 items-center">
																		<div className="rounded-full bg-white w-fit h-fit p-1" />
																	</div>
																)}
																{FormatDateToLocalString(date)}
															</li>
														))}
													</ul>
												)
											) : (
												<Loader />
											)}

											<button
												className="comuna-primary-button sm mt-10 self-end"
												onClick={() => {
													setConfirming(true);
													// handleResponseInterview(interviewId, "accept")
												}}>
												Continue
											</button>
											{/* <button
									onClick={() => handleResponseInterview(interviewId, "reject")}
									className="text-red-500 underline text-left mt-2 ml-2">
									Can't make any date
								</button> */}
										</div>
									)}
									{/** Confirm Timezone */}
									{reqSuccess === "accept" && (
										<div className="">
											<div className="flex flex-col rounded-lg items-center p-6">
												<img
													src={laptopGuyGraphic}
													className="w-fit h48 mb-12"
												/>
												<b className="w-full text-3xl font-semibold text-center">
													Done!
												</b>
												<p className="my-4 text-center">
													You've set up an interview with
													<label className="comuna-purple font-semibold">
														{" "}
														Upstart13
													</label>{" "}
													for{" "}
													<b className="comuna-purple font-semibold">
														{FormatDateToLocalString(selectedDate)}
													</b>
												</p>
												<p className="text-center">
													In the meanwhile, you can learn more about the company
													and get prepared. We wish you the best of luck!
												</p>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="w-8/12 h-full flex flex-col bg-white rounded-xl mt-4 ml-2">
						<div className="w-full h-fit">
							<OrgAboutView proposal={datesInfo} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContractorInterviewConfirmation;
