import { useState } from "react";

interface HiringProgressTrackerProps {
	currentState: number;
	interviewing: number;
	yourTurn: number;
	peopleIWantToWorkWith: number;
	hired: number;
	archived: number;
	showArchived: boolean;
	onClickInterviewing: () => void;
	onClickYourTurn: () => void;
	onClickPeopleIWantToWorkWith: () => void;
	onClickHired: () => void;
	onSelectArchived: (viewArchived: boolean) => void;
	isView: boolean;
}

export default function HiringProgressTracker({
	currentState,
	interviewing,
	yourTurn,
	peopleIWantToWorkWith,
	hired,
	archived,
	showArchived,
	onClickInterviewing,
	onClickYourTurn,
	onClickPeopleIWantToWorkWith,
	onClickHired,
	onSelectArchived,
	isView
}: HiringProgressTrackerProps) {
	//horizontal progress tracker, with four states:
	//1-MUNA is Interviewing
	//2-Your turn to interview**
	//3-people I want to work with**
	//4-Hired**
	//
	//the progress tracker should be rounded on the left and right ends,
	//and if the state is not yet reached, the progress tracker's background color will be white
	//if the state is reached, the progress tracker's background color will be comuna-purple-light
	//each state has a counter of the number of requests that are in that state e.g. (2) or (23)
	//the counter should be displayed at the right of the state name on each state
	//each state is a button that can be clicked to show the requests in that state

	var selectedClassName =
		"bg-purple-dark w-full h-full text-center justify-center pr-3 py-4";
	var notSelectedClassName =
		"bg-white w-full h-full text-center justify-center pr-3 py-4";

	const [showActive, setShowActive] = useState(true);

	return (
		<div className="flex flex-col w-full mt-5 mb-5">
			<div className="flex flex-row items-start border-b-2 border-gray-200">
				<button
					onClick={() => {
						setShowActive(true);
						onSelectArchived(true);
					}}
					className={
						"font-semibold py-3 px-5 " +
						(showActive ? " comuna-purple border-b-4 border-comuna-purple" : "")
					}>
					Active
					{" (" +
						(interviewing + yourTurn + peopleIWantToWorkWith + hired) +
						")"}
				</button>
				<button
					onClick={() => {
						setShowActive(false);
						onSelectArchived(false);
					}}
					className={
						"font-semibold py-3 px-5 " +
						(!showActive
							? " comuna-purple border-b-4 border-comuna-purple"
							: "")
					}>
					Archived
					{" (" + archived + ")"}
				</button>
			</div>
			{!showArchived && (
				<div className="flex w-full mt-5 flex-row-reverse hover-card mb-5 bg-transparent rounded-xl">
					<div
						className={
							(currentState == 3 ? selectedClassName : notSelectedClassName) +
							" rounded-r-lg"
						}
						style={{
							paddingLeft: "30px",
							marginLeft: "-30px",
							height: "60px",
						}}>
						<button className="font-semibold" onClick={onClickHired}>
							Hired
							<label className={currentState === 3 ? "" : "text-gray-400"}>
								{" "}
								{`(${hired})`}
							</label>
						</button>
					</div>
					<div
						className={"arrow-right greyarrow"}
						style={{
							marginLeft: "-29px",
							zIndex: 1,
						}}
					/>
					<div
						className={
							"arrow-right " +
							(currentState == 2 ? " purplearrow " : " whitearrow")
						}
						style={{
							zIndex: 1,
						}}
					/>
					<div
						className={
							currentState == 2 ? selectedClassName : notSelectedClassName
						}
						style={{
							paddingLeft: "30px",
							marginLeft: "-30px",
							height: "60px",
						}}>
						<button
							className="font-semibold"
							onClick={onClickPeopleIWantToWorkWith}>
							{isView ? "People we want to work with" : "People I want to work with"}

							<label className={currentState === 2 ? "" : "text-gray-400"}>
								{" "}
								{`(${peopleIWantToWorkWith})`}
							</label>
						</button>
					</div>
					<div
						className={"arrow-right greyarrow"}
						style={{
							marginLeft: "-29px",
							zIndex: 1,
						}}
					/>
					<div
						className={
							"arrow-right " +
							(currentState == 1 ? " purplearrow " : " whitearrow")
						}
						style={{
							zIndex: 1,
						}}
					/>
					<div
						className={
							currentState == 1 ? selectedClassName : notSelectedClassName
						}
						style={{
							paddingLeft: "30px",
							marginLeft: "-30px",
							height: "60px",
						}}>
						<button
							className="font-semibold overflow-x-auto h-full w-full"
							onClick={onClickYourTurn}>
							{isView ? "Our turn to interview" : "Your turn to interview"}
							{/* {yourTurn > 0 && `(${yourTurn})`} */}
							<label className={currentState === 1 ? "" : "text-gray-400"}>
								{" "}
								{`(${yourTurn})`}
							</label>
						</button>
					</div>
					<div
						className={"arrow-right greyarrow"}
						style={{
							marginLeft: "-29px",
							zIndex: 1,
						}}
					/>
					<div
						className={
							"arrow-right " +
							(currentState == 0 ? " purplearrow " : " whitearrow")
						}
						style={{
							zIndex: 1,
						}}
					/>

					<div
						className={
							(currentState == 0 ? selectedClassName : notSelectedClassName) +
							" rounded-l-lg"
						}
						style={{
							height: "60px",
						}}>
						<button className="font-semibold" onClick={onClickInterviewing}>
							MUNA is interviewing
							{/* {interviewing > 0 && `(${interviewing})`} */}
							<label className={currentState === 0 ? "" : "text-gray-400"}>
								{" "}
								{`(${interviewing})`}
							</label>
						</button>
						{/* {currentState <= 1 ?
                } */}
					</div>
				</div>
			)}
		</div>
	);
}
