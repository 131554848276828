import { useContext, useEffect, useState } from "react";
import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";
import { Loader } from "./loader";
import { strings } from "../strings";
import { ComunaLogo } from "./comunaLogo";

import zxcvbn from "zxcvbn";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { Link, useParams } from "react-router-dom";

export function SignUp() {
	const query = new URLSearchParams(window.location.search);

	const { token, setToken, verified, setVerified } = useContext(UserContext);

	const [includesEmail, setIncludesEmail] = useState(false);

	const [newemail, setNewEmail] = useState(null);

	const [Loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [passMatch, setPassMatch] = useState(true);
	const [error, setError] = useState("");

	const [initToken, setInitToken] = useState("");

	const logo = require("../img/muna-logo.png");
	const img1 = require("../img/graphics/graphic_1.jpg");

	useEffect(() => {
		var qNewEmail;
		if (query.get("newemail") !== null) {
			if (query.get("newemail").includes(" ")) {
				qNewEmail = query.get("newemail").split(" ").join("+");
			} else {
				qNewEmail = query.get("newemail");
			}
			if (qNewEmail !== null) {
				console.log("new email: ", qNewEmail);
				setIncludesEmail(true);
				setNewEmail(qNewEmail);
				setEmail(qNewEmail);
			}
		} else {
			console.log("no new email:", newemail);
		}
	}, [newemail]);

	const validatePassword = () => {
		if (password.length < 8) {
			setError("Password is too short");
			return false;
		}
		if (password !== confirmPassword) {
			setError("Passwords don't match");
			return false;
		}
		if (zxcvbn(password).score < 2) {
			setError("Passwords is too weak, use letters numbers and symbols");
			return false;
		}
		return true;
	};

	const handleSubmit = (e) => {
		setError("");
		e.preventDefault();

		if (validatePassword()) {
			signUp();
		}
	};

	async function signUp() {
		var newEmail = "";
		try {
			setLoading(true);
			const signUpReturn = await ComunaAPI.signUp(
				email,
				password,
				confirmPassword
			);
			newEmail = signUpReturn.email;

			if (newEmail === email) {
				signIn(email, password);
			}
		} catch (error) {
			if (error.response.status === 400 && error.response.data.email) {
				setError(
					"An account with this email, already exists. Did you forgot your password?"
				);
			} else {
				setError("There was an error with your request, please try again");
			}
		} finally {
			setLoading(false);
		}
	}

	async function signIn(email, password) {
		var newToken = "";
		try {
			const signInReturn = await ComunaAPI.signIn(email, password);
			newToken = signInReturn.token;
		} catch (error) {
			setError(error);
		}

		if (newToken !== "") {
			LocalStorageManager.setInitToken(newToken);
			setInitToken();
			getUser(newToken);
		}
	}

	async function getUser(newToken) {
		try {
			const userReturn = await ComunaAPI.getUserData(newToken);
			console.log("user returned: ", userReturn);
			if (userReturn.email_verified) {
				await setToken(newToken);
				var wantedURL = LocalStorageManager.getWantedURL();

				setTimeout(() => {
					// window.location.href = "/hiring";
					if (LocalStorageManager.getWantedURL() !== "") {
						console.log("found wantedURL: " + wantedURL);
						// LocalStorageManager.setWantedURL("");
						window.location.href = wantedURL;
					} else {
						window.location.href = "/hiring";
					}
				}, 0.2);
			} else {
				window.location.href = "/clientonboarding";
			}
		} catch {
			setError("Invalid token?");
		} finally {
			setLoading(false);
		}
	}

	const regularInputClassName =
		"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";
	const errorInputClassName =
		"shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";

	return (
		<div className="flex flex-row w-full h-full">
			<div className="graphic flex flex-row  h-full place-content-center">
				<div className="fade-in w-full flex flex-col h-full text-center items-center justify-center">
					<img
						src={img1}
						style={{ width: "60%", height: "auto", minWidth: 400 }}
						alt="people smiling and decorative colored shapes"
					/>
					<p
						style={{ width: "60%", height: "auto" }}
						className="comuna-purple font-bold text-left mt-8 text-2xl"
						dangerouslySetInnerHTML={{
							__html: strings.login_screen_copy_clients,
						}}></p>
				</div>
			</div>
			<form
				className="w-full bg-white"
				onSubmit={handleSubmit}
				style={{
					paddingRight: "30%",
					paddingLeft: "150px",
					paddingBottom: "15%",
					paddingTop: "150px",
				}}>
				<ComunaLogo big={true} />
				<h2 className="mt-10">Start hiring with MUNA</h2>
				{/* <p className="mb-16">Sign in your account</p> */}
				<p className="mb-16">
					Let's get you set up to hire your remote team. It all starts by
					creating a MUNA account.
				</p>

				<div className="mb-4">
					<label htmlFor="email">Email</label>
					{newemail !== undefined && newemail !== null ? (
						<div className="rounded-xl border-gray-200 p-3 border-2">
							<label className=" " htmlFor="email">
								{newemail}
							</label>
						</div>
					) : (
						<input
							className="comuna-input "
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							type="email"
							placeholder="Email*"
							id="email"
							name="email"
						/>
					)}
				</div>
				<div className="mb-4">
					<label htmlFor="password">Create password</label>
					<input
						className="comuna-input"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						type="password"
						placeholder="Create password"
						id="password"
						name="password"
					/>
				</div>
				<div className="mb-4">
					<label htmlFor="passwordConfirm">Repeat password</label>
					<input
						className="comuna-input"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						type="password"
						placeholder="Confirm password"
						id="passwordConfirm"
						name="passwordConfirm"
					/>
				</div>

				<button type="submit" className="comuna-primary-button mt-8">
					{Loading ? <Loader /> : "Sign Up"}
				</button>
				{passMatch ? null : (
					<div className="text-red-600 mt-4">Password don't match</div>
				)}
				{error === "" ? null : <div className="text-red-600 mt-4">{error}</div>}
				<div className="flex flex-row text-center w-full mt-5">
					<label className="w-full">
						Already have an account?
						<Link
							className="comuna-link-small w-full text-end font-normal"
							to={"/"}>
							{" "}
							Sign in?
						</Link>
					</label>
				</div>
			</form>
		</div>
	);
}
