import { Link } from "react-router-dom";

export function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-4 mt-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-start items-start">
          <div className="text-sm mb-2 sm:mb-0">
            &copy; {new Date().getFullYear()} MUNA. All rights reserved.
          </div>
          <div className="sm:ml-4">
          <Link to="/privacy_policy" className="text-sm text-gray-400 hover:text-white">
            Privacy Policy
          </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};