import { formatStringDateFromServer } from "../utils/formaters";
import InvoiceState from "./InvoiceState";

interface ClientInvoiceItemProps {
	onClick: () => void;
	invoice: any;
}

const ClientInvoiceItem: React.FC<ClientInvoiceItemProps> = ({
	onClick,
	invoice,
}) => {
	var invoiceData = {
		billing_cycle: "2023-10-30",

		client_contract: 3,

		comuna_fee: -147,

		date_created: "2023-10-18",

		date_payed: null,

		id: 1,

		invoice_details:
			"<table><tr> <td>MarioC1 Ruiz</td> <td>Frontend developer</td> </tr><tr> <td>Salary</td> <td>-833.333333333333</td> </tr><tr> <td>Gy…",

		payment_gateway: 0,

		payment_method: null,

		status: "created",

		total_benefits: -640,

		total_salary: -833,
	};

	return (
		<tr
			style={{
				height: 57,
				cursor: "pointer",
			}}
			onClick={onClick}
			className="hover-card rounded-xl">
			<td
				className="bg-comuna-purple-light-alpha"
				style={{
					borderTopLeftRadius: 10,
					borderBottomLeftRadius: 10,
					paddingLeft: 15,
				}}>
				<b>{formatStringDateFromServer(invoice.billing_cycle)}</b>
			</td>
			<td className="bg-comuna-purple-light-alpha">
				{formatStringDateFromServer(invoice.billing_cycle)}
			</td>
			<td className="bg-comuna-purple-light-alpha">
				{invoice.date_payed === null
					? "pending"
					: formatStringDateFromServer(invoice.date_payed)}
			</td>
			<td className="bg-comuna-purple-light-alpha comuna-purple font-bold">
				{"$ " +
					(
						invoice.total_salary +
						invoice.total_benefits +
						invoice.one_time_total +
						invoice.comuna_fee +
						invoice.payment_gateway
					)
						.toLocaleString("En-us")
						.split(".")[0]}
			</td>
			<td
				className="bg-comuna-purple-light-alpha"
				style={{
					borderTopRightRadius: 10,
					borderBottomRightRadius: 10,
				}}>
				<InvoiceState status={invoice.status} />
			</td>
		</tr>
	);
};

export default ClientInvoiceItem;
