import React, { useEffect, useState } from 'react';
import { ContractorImageIcon, AgentImageIcon } from './Icons';

interface Entry {
  timestamp?: string;
  speaker: string;
  text: string;
  isContractor: boolean;
}

export interface TranscriptComponentProps {
  transcription: string;
  contractorTranscriptionName: string,
  contractor: {
    first_name: string;
    last_name: string;
    profile_picture?: string;
  };
  agent: {
    name: string;
  };
}

const TranscriptComponent: React.FC<TranscriptComponentProps> = ({ transcription, contractorTranscriptionName, contractor, agent }) => {

  const [transcriptContent, setTranscriptContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTranscription = async () => {
      try {
        const response = await fetch(transcription);
        if (!response.ok) {
          throw new Error('Failed to fetch transcription');
        }
        const text = await response.text();
        setTranscriptContent(text);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTranscription();
  }, [transcription]);

  const parseTranscription = (transcription: string, contractorTranscriptionName: string): Entry[] => {
    const entryRegex = /(?:\[\d{2}:\d{2}:\d{2}\]\s)?([^\r\n:]+):\s/g;
    let entryMatch: RegExpExecArray | null;
    const entries: Entry[] = [];
    let lastIndex = 0;
    const contractorFullName = `${contractor.first_name} ${contractor.last_name}`;

    while ((entryMatch = entryRegex.exec(transcription)) !== null) {
      if (lastIndex !== 0) {
        const text = transcription.slice(lastIndex, entryMatch.index).trim();
        if (text) {
          const lastEntry = entries[entries.length - 1];
          if (lastEntry) {
            lastEntry.text += ` ${text}`;
          }
        }
      }

      const timestampMatch = entryMatch[0].match(/\[(.*?)\]/);
      const timestamp = timestampMatch ? timestampMatch[1] : undefined;
      const isContractor = entryMatch[1].trim() === contractorTranscriptionName;

      entries.push({
        timestamp,
        speaker: isContractor ? contractorFullName : agent.name,
        text: '',
        isContractor,
      });

      lastIndex = entryRegex.lastIndex;
    }

    if (lastIndex < transcription.length) {
      const text = transcription.slice(lastIndex).trim();
      if (text) {
        const lastEntry = entries[entries.length - 1];
        if (lastEntry) {
          lastEntry.text += ` ${text}`;
        }
      }
    }

    return entries;
  };

  if (loading) {
    return <div>Loading transcription...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!transcriptContent) {
    return <div>No transcription available.</div>;
  }

  const entries = parseTranscription(transcriptContent, contractorTranscriptionName);

  return (
    <div className="space-y-2">
      {entries.map((entry, index) => (
        <div className="flex flex-row mb-4" key={index}>
          {entry.isContractor ? (
            <ContractorImageIcon contractor={contractor} />
          ) : (
            <AgentImageIcon agent={agent} />
          )}
          <div className="flex flex-col mt-1">
            <div className="flex items-center space-x-2">
              <span className="font-bold">{entry.speaker}</span>
              <span className={`text-xs font-semibold rounded-md big ${entry.isContractor ? 'yellow-badge' : 'purple-badge'}`}>
                {entry.isContractor ? 'Candidate' : 'MUNA staff'}
              </span>
            </div>
            <div className="flex flex-col justify-between items-end rounded-xl bg-purple-light mt-2 px-4 py-4">
              <p className="text-gray-800 mb-1">{entry.text}</p>
              {entry.timestamp && (
                <span className="relative absolute bottom-0 right-0 text-xs text-gray-400">{entry.timestamp}</span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TranscriptComponent;