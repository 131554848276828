import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormatLink } from "../utils/formaters";

import SalaryBadge from "./SalaryBadge";

import CompatibilityComponent from "./TechnicalInterview/CompatibilityComponent";

const hot_candidate_icon = require("../img/icons/hot_candidate.png");
const resume_icon = require("../img/icons/resume-icon.png");
const linkedin_icon = require("../img/icons/linkedin-icon.png");
const web_icon = require("../img/icons/web-icon.png");
const fix_icon_purple = require("../img/icons/fix-icon-purple.png");
const fix_icon_white = require("../img/icons/fix-icon-white.png");
const coins_icon = require("../img/icons/coins-icon.png");
const arrow_black = require("../img/icons/black-arrow-right.png");
const arrow_gray = require("../img/icons/gray-arrow-right.png");
const calendar_icon = require("../img/icons/calendar-icon.png");
const clock_icon = require("../img/icons/clock-icon.png");
const sliders_icon = require("../img/icons/sliders-03.png");

interface ClientApplicationCardProps {
	request: any;
	application: any;
    isFixed: boolean;
	onFix: (id: number) => void;
	isView: boolean;
	organizationDetails: any
}

export function ClientApplicationCard({
	application,
	request,
	isFixed,
	onFix,
	isView = false,
	organizationDetails
}: ClientApplicationCardProps) {

	const [readMore, setReadMore] = useState(false);
	const [noteLength, setNoteLength] = useState(0);
    const [fixed, setFixed] = useState(isFixed);

	const small = (application && application.state < 1 && (!application.interview || application.interview.score <= 0));
	useEffect(() => {
		if (application !== null && application !== undefined) {
			var noteLnght = application.contractor.note_for_client?.split("").length;
			setNoteLength(noteLnght);
		}
	}, [application]);

	useEffect(() => {
        setFixed(isFixed);
    }, [isFixed]);

	function GetQuickActionButtonsMargin() {
		if (application.interview && application.interview.score > 0 && organizationDetails) {
			return 125;
		}
		return 15;
	}

	function StateBadge(): any {
		if (application.proposal != null && application.proposal.state === 1) {
			return <div className="green-badge big">Hired</div>;
		}

		if (application.proposal != null && application.proposal.state === 3) {
			return <div className="red-badge big">Rejected</div>;
		}

		if (application.state === 0) {
			return <div className="blue-badge big">Screening Candidate</div>;
		}
		if (application.state === 1) {
			return <div className="orange-badge big">Cultural Fit Interview</div>;
		}
		if (application.state === 2) {
			return <div className="yellow-badge big">Technical Interview</div>;
		}
		if (application.state === 3) {
			return <div className="purple-badge big">Partner Interview</div>;
		}
		if (application.state === 4) {
			return <div className="blue-badge big">Offer Sent</div>;
		}

		//if hired
		if (application.state === 5) {
			return <div className="green-badge big mr-2">Hired</div>;
		}

		if (application.state === 6) {
			return <div className="red-badge big mr-2">Rejected</div>;
		}
	}

	function InterviewBadge() {
		// //if hired
		// const proposalSent = application.state >= 4;

		// //if is past the cultural interview stage
		// const canAskForInterview = application.state > 1;
		//was the interview requested?
		const interviewRequested =
			application.interview_available_dates !== null ||
			application.interview_calendar_link !== null;

		//is the interview scheduled?
		const interviewScheduled = application.interview_call_link != null;

		if (interviewScheduled) {
			return (
				<div className="blue-badge big flex flex-row mr-2 items-center" style={{ minWidth: 170 }}>
					<img
						style={{
							width: 16,
							height: 16,
							bottom: 10,
							right: -10,
						}}
						src={clock_icon}
						alt="Fire icon"
						className="mr-1"
					/>
					<div>Interview scheduled</div>
				</div>
			);
		}

		if (interviewRequested) {
			return (
				<div className="blue-badge big flex flex-row mr-2 items-center min-w-fit">
					<img
						style={{
							width: 16,
							height: 16,
							bottom: 10,
							right: -10,
						}}
						src={clock_icon}
						alt="Fire icon"
						className="mr-1"
					/>
					Interview requested
				</div>
			);
		}

		return <></>;
	}

	function Experience(): any {
		return (
			<div className="w-full bg-purple-light mx-2 rounded-xl text-center py-2 text-black font-bold flex flex-row justify-center items-center">
				<img
					style={{ width: 12, marginRight: 3 }}
					src={arrow_black}
					alt="arrow pointing right"
				/>
				<img
					style={{ width: 12, marginRight: 3 }}
					src={application.contractor.seniority > 1 ? arrow_black : arrow_gray}
					alt="arrow pointing right"
				/>
				<img
					style={{ width: 12, marginRight: 3 }}
					src={application.contractor.seniority > 2 ? arrow_black : arrow_gray}
					alt="arrow pointing right"
				/>
				<img
					style={{ width: 12, marginRight: 7 }}
					src={application.contractor.seniority > 3 ? arrow_black : arrow_gray}
					alt="arrow pointing right"
				/>
				{application.contractor.seniority === 1 && <span>2 yrs xp </span>}
				{application.contractor.seniority === 2 && <span>4 yrs xp </span>}
				{application.contractor.seniority === 3 && <span>6+ yrs xp </span>}
				{application.contractor.seniority === 4 && <span>6+ yrs xp </span>}
			</div>
		);
	}


	function Salary(): any {
		

		return (
			<>
			{organizationDetails?.direct_contract?(
				<div className="w-full bg-comuna-blue-light mx-2 rounded-xl text-center py-2 text-black font-bold  flex flex-row justify-center">
				<img
					src={sliders_icon}
					alt="four triangles indicating level"
					style={{ height: 24, width: 24, marginRight: 12 }}
				/>
				<label className="text-m">${Math.round(application.salary_recommendation / 12 / 160).toLocaleString() + " /hr"} </label>
			</div>
		):(
			<div className="w-full bg-comuna-yellow-light mx-2 rounded-xl text-center py-2 text-black font-bold  flex flex-row justify-center">
				<img style={{ width: 22, height: 22, marginRight: 8 }} src={coins_icon} alt="" />
				<span>
					USD {application.contractor.salary_expectation.toLocaleString("en-US")}/yr
				</span>
				<SalaryBadge salary={application.contractor.salary_expectation} recommendation={application.salary_recommendation} />
			</div>
			)}
			</>
			
		);
	}

	function Skills() {
		return (
			<div className="flex flex-row flex-wrap">
				{application.contractor.skills.map((skill: any, i: number) => {
					return (
						<span key={i} className="skill-badge big mr-2 mb-2">
							{skill}
						</span>
					);
				})}
			</div>
		);
	}

	function InterviewButton() {
		//if hired
		const proposalSent = application.state === 4;
		const accepted = application.state === 5;
		const rejected = application.state === 6;

		//if is past the cultural interview stage
		const canAskForInterview = application.state > 1;
		//was the interview requested?
		const interviewRequested =
			application.interview_available_dates != null ||
			application.interview_calendar_link != null;
		//is the interview scheduled?
		const interviewScheduled = application.interview_call_link != null;

		if (accepted) {
			return (
				<button
					className="comuna-disabled-button icon font-bold flex flex-row"
					style={{ borderWidth: 0 }}>
					<span>Hired</span>
				</button>
			);
		}

		if (rejected) {
			return (
				<button
					className="comuna-disabled-button icon font-bold flex flex-row"
					style={{ borderWidth: 0 }}>
					<span>Rejected</span>
				</button>
			);
		}

		if (proposalSent) {
			return (
				<button
					className="comuna-disabled-button icon font-bold flex flex-row tooltip"
					style={{ borderWidth: 0 }}>
					<span>Proposal Sent</span>
					<span className="tooltiptext">
						You already sent a work proposal to this candidate.
					</span>
				</button>
			);
		}

		if (interviewScheduled) {
			return (
				<button
					className="comuna-disabled-button icon font-bold flex flex-row"
					style={{ borderWidth: 0 }}>
					<img src={calendar_icon} alt="" />
					<span>Interview scheduled</span>
				</button>
			);
		}

		if (interviewRequested) {
			return (
				<button
					className="comuna-disabled-button icon font-bold flex flex-row"
					style={{ borderWidth: 0 }}>
					<img src={clock_icon} alt="" />
					<span>Interview requested</span>
				</button>
			);
		}

		if (canAskForInterview) {
			return (
				<Link
					to={
						"/hiring/" +
						request.id +
						"/application/" +
						application.id +
						"/interview"
					}
					className="comuna-secondary-button bg-purple-light icon font-bold flex flex-row  hover-bg-purple-dark"
					style={{ borderWidth: 0 }}>
					<span>Ask for interview</span>
				</Link>
			);
		} else {
			return (
				<button
					className="comuna-disabled-button icon font-bold flex flex-row tooltip"
					style={{ borderWidth: 0 }}>
					<span>Ask for interview</span>
					<span className="tooltiptext">
						You can’t ask this candidate for an interview yet because their
						application is still on the cultural fit interview stage.
					</span>
				</button>
			);
		}
	}

	const ConditionalLink: React.FC<{ children: React.ReactNode }> = ({ children }) => {
		return small || isView ? (
			<div>{children}</div>
		) : (
			<Link to={`/hiring/${request.id}/application/${application.id}`}>{children}</Link>
		);
	};

	return (
		<div>
			<div className="comuna-card p-4 black-text mb-4 hover-card w-full relative">
				<ConditionalLink >

				{application.interview && application.interview.score > 0 &&
					organizationDetails &&
						<div className="mb-4">
							<CompatibilityComponent
								company_name={organizationDetails.name}
								small={true}
								agent={request.comuna_agent}
								averageScore={application.interview.score}
								contractor={application.contractor} />
						</div>}

					<div className="flex flex-row justify-start">
						{/**Profile Picture */}
						<div className="relative" style={{ width: 84, height: 84 }}>
							{application.contractor.profile_picture && (
								<img
									src={application.contractor.profile_picture}
									alt=""
									style={{ height: 84, width: 84, borderRadius: "50%" }}
								/>
							)}
							{!application.contractor.profile_picture && (
								<div
									className="bg-purple-light flex justify-center items-center font-bold comuna-purple text-4xl"
									style={{
										height: 84,
										width: 84,
										borderRadius: "50%",
										lineHeight: -10,
									}}>
									{application.contractor.first_name.charAt(0).toUpperCase() +
										application.contractor.last_name.charAt(0).toUpperCase()}
								</div>
							)}
							{application.contractor.top_candidate && (
								<img
									style={{
										width: 32,
										height: 32,
										position: "absolute",
										bottom: 10,
										right: -10,
									}}
									src={hot_candidate_icon}
									alt="Fire icon"
								/>
							)}
						</div>

						{/**Name Title and badges */}
						<div className="flex flex-col ml-4">
							<h3
								className={`font-semibold black-text flex-1 ${small? "mt-2":""}`}
								style={{
									height: 31,
									maxWidth: "9em",
									wordBreak: "break-word",
								}}>
								{application.contractor.first_name +
									" " +
									application.contractor.last_name}
							</h3>
							<span className={small ? "text-base mb-3":"mb-2"}>
								{application.contractor.title} -{" "}
								{application.contractor.country_location}
							</span>
							{!small && (
								<div className="flex flex-row w-full">
									<StateBadge />
									<InterviewBadge />
								</div>
							)}

						</div>

					</div>

					<div className="separator"></div>

					<div className="flex flex-row">
						<Experience />
						<Salary />
					</div>

					<div className="separator"></div>

					<Skills />

					{!small && (
						<div className="text-lg mt-4 font-bold text-left">
							Notes from MUNA
						</div>
					)}
				</ConditionalLink>

				{/**Quick Actions Buttons */}
				<div className="flex flex-row items-start justify-end" style={{ position: "absolute", top: GetQuickActionButtonsMargin(), right: 15 }}>
					{application.contractor.website && application.contractor.website !== "null" && (
						<a
							onClick={(e) => {
								e.stopPropagation();
							}}
							target="_new"
							className="comuna-circle-button"
							href={FormatLink(application.contractor.website)}>
							<img
								src={web_icon}
								alt="Button to visit website of the candidate"
							/>
						</a>
					)}

					{application.contractor.linkedin && application.contractor.linkedin !== "null" && (
						<a
							onClick={(e) => {
								e.stopPropagation();
							}}
							target="_new"
							className="comuna-circle-button"
							href={FormatLink(application.contractor.linkedin)}>
							<img
								src={linkedin_icon}
								alt="Button to go to linkedin of the candidate"
							/>
						</a>
					)}

					{application.contractor.cv && application.contractor.cv !== "null" && (
						<a
							onClick={(e) => {
								e.stopPropagation();
							}}
							target="_new"
							className="comuna-circle-button"
							href={FormatLink(application.contractor.cv)}>
							<img src={resume_icon} alt="Button to download resume" />
						</a>
					)}

					<button
						onClick={() =>{
							onFix(application.id);
							setFixed(!fixed);
						}}
						className={"comuna-circle-button" + (isFixed ? " alt-background" : "")}>
						<img
							src={isFixed ? fix_icon_white : fix_icon_purple}
							alt="Button to fix candidate"
							className="max-w-full max-h-full object-contain"
						/>
					</button>
				</div>

				{!small && (<React.Fragment>
					{noteLength > 25 ? (
						<div className="text-left w-full justify-between">
							{/** wrap text at max 20 words */}
							<p className="text-left">
								{readMore
									? application.contractor.note_for_client
									: ("" + application.contractor.note_for_client).substring(
										0,
										25
									) + "..."}
							</p>
							<button
								onClick={(e) => {
									e.stopPropagation();
									setReadMore(!readMore);
								}}
								className="comuna-purple cursor-pointer">
								{readMore ? "   Read Less" : "...   Read More"}
							</button>
						</div>
					) : (
						<Link to={"/hiring/" + request.id + "/application/" + application.id}>
							<div className="text-left w-full justify-between">
								{/** wrap text at max 20 words */}
								<p className="text-left">
									{readMore
										? application.contractor.note_for_client
										: ("" + application.contractor.note_for_client).substring(
											0,
											25
										) + "..."}
								</p>
								<button
									onClick={(e) => {
										e.stopPropagation();
										setReadMore(!readMore);
									}}
									className="comuna-purple cursor-pointer">
									{readMore ? "   Read Less" : "...   Read More"}
								</button>
							</div>
						</Link>
					)}

					<div className="separator" />
					{!isView && <InterviewButton />}
					
				</React.Fragment>)}

			</div>
		</div>
	);
}
