import React from "react";

// Import your logo if needed
// const logoBlack = require('../img/logo_black.png');

export function PrivacyPolicy() {
    return (
        <div className="w-full min-h-screen bg-comuna-purple flex items-center justify-center px-4 py-12">
            <div className="max-w-3xl w-full bg-white shadow-lg rounded-xl p-8">
                <header className="text-center mb-6">
                    <h1 className="text-4xl font-extrabold text-comuna-purple">
                        Data Protection Policy
                    </h1>
                </header>

                <div className="space-y-6 text-gray-700">
                    <section>
                        <p>
                            This Privacy Policy outlines how Muna Ai ("we," "our," "us") collects, uses, discloses, and protects the personal information of users ("you," "you’re") of our website. MUNA - We help companies hire high quality international tech workers. ("Website").
                        </p>
                    </section>

                    {/* Information We Collect */}
                    <section>
                        <h2 className="text-2xl font-semibold text-comuna-purple mb-2">
                            Information We Collect
                        </h2>
                        <ul className="list-disc list-inside space-y-2">
                            <li>
                                <strong>Personal Information:</strong> When you visit our Website, we may collect personal information that you voluntarily provide to us, such as your name, email address, phone number, and any other information you choose to provide.
                            </li>
                            <li>
                                <strong>Automated Information:</strong> We may automatically collect certain information about your visit to our Website, including your IP address, browser type, operating system, referring URLs, pages visited, and other usage information.
                            </li>
                        </ul>
                    </section>

                    {/* Use of Information */}
                    <section>
                        <h2 className="text-2xl font-semibold text-comuna-purple mb-2">
                            Use of Information
                        </h2>
                        <p>
                            We may use the information we collect for the following purposes:
                        </p>
                        <ul className="list-disc list-inside space-y-2 mt-2">
                            <li>To provide, operate, and maintain our Website;</li>
                            <li>To improve, personalize, and enhance your experience on our Website;</li>
                            <li>To understand and analyze how you use our Website and develop new products, services, features, and functionality;</li>
                            <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Website, and for marketing and promotional purposes;</li>
                            <li>To process your transactions and manage your orders;</li>
                            <li>To comply with legal obligations and resolve any disputes that we may have.</li>
                        </ul>
                    </section>

                    {/* Disclosure of Information */}
                    <section>
                        <h2 className="text-2xl font-semibold text-comuna-purple mb-2">
                            Disclosure of Information
                        </h2>
                        <p>
                            We may disclose your personal information to third-parties for the following purposes:
                        </p>
                        <ul className="list-disc list-inside space-y-2 mt-2">
                            <li>
                                To our service providers who assist us in operating our Website, conducting our business, or servicing you, subject to appropriate confidentiality and security measures;
                            </li>
                            <li>
                                To comply with applicable laws and regulations, to respond to a subpoena, search warrant, or other lawful request for information we receive, or to otherwise protect our rights.
                            </li>
                        </ul>
                    </section>

                    {/* Data Security */}
                    <section>
                        <h2 className="text-2xl font-semibold text-comuna-purple mb-2">
                            Data Security
                        </h2>
                        <p>
                            We take reasonable measures to protect your personal information from unauthorized access, use, alteration, or destruction. However, please be aware that no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                        </p>
                    </section>

                    {/* Your Rights */}
                    <section>
                        <h2 className="text-2xl font-semibold text-comuna-purple mb-2">
                            Your Rights
                        </h2>
                        <p>
                            You have the right to access the personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us using the contact information below.
                        </p>
                    </section>

                    {/* Changes to This Privacy Policy */}
                    <section>
                        <h2 className="text-2xl font-semibold text-comuna-purple mb-2">
                            Changes to This Privacy Policy
                        </h2>
                        <p>
                            We may update this Privacy Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons.
                        </p>
                    </section>

                    {/* Contact Us */}
                    <section>
                        <h2 className="text-2xl font-semibold text-comuna-purple mb-2">
                            Contact Us
                        </h2>
                        <p>
                            If you have any questions about this Privacy Policy, please contact us at:
                        </p>
                        <ul className="list-none space-y-1 mt-2">
                            <li>
                                <a href="mailto:contact@thecomuna.com" className="text-comuna-purple hover:underline">
                                    contact@thecomuna.com
                                </a>
                            </li>
                            <li>
                                <a href="mailto:contact@munaai.com" className="text-comuna-purple hover:underline">
                                    contact@munaai.com
                                </a>
                            </li>
                        </ul>
                    </section>
                </div>

                {/* Optional Footer */}
                <footer className="mt-8 text-center text-gray-500 text-sm">
                    &copy; {new Date().getFullYear()} Muna Ai. All rights reserved.
                </footer>
            </div>
        </div>
    );
}