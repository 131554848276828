
import { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useRouteLoaderData } from "react-router-dom";
import { UserContext } from "../Providers/UserContext";
import { formatDateFromString, formatAmountCommas } from "../utils/formaters";
import { Loader } from "./loader";
import { ContractorProfilePicture } from "./ContractorProfilePicture";
import { BenefitItem } from "./BenefitItem";
import { create } from "domain";
import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
const xIcon = require("../img/icons/x.png");



export function ContractorReviewChanges(){
    const { proposal, contracts }: any = useRouteLoaderData("contractor_root");
	const { user, logOut }:any = useContext(UserContext);
	const [pendingChange, setPendingChange] = useState({payment_cadence: "------", salary:0, benefits:[], contract_id:0});
    const [loading, setLoading] = useState(false);
    const [showDisagree, setShowDisagree] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [organization, setOrganization] = useState("");

	useEffect(()=>{
		PendingContractChanges();
	}, [])

    function PendingContractChanges(){
		var pendingChanges = 0;
		if(contracts!==null){	
			contracts.forEach((contract:any) => {
				if(contract.pending_changes.length>0){
					contract.pending_changes.forEach((change:any) =>{
						if(change.applied === false && change.approved_by_contractor === false){
							pendingChanges++;//there should only be one pending change per contract
                            change.contract_id = contract.id;
							setPendingChange(change);
                            setOrganization(contract.organization.name);
                            console.log("PENDING CHANGES ", change);
						}
					})
				}
			})
		}
		return pendingChanges;
	}


	function GetUpdateDate() {
		var date = new Date();
		//update the current date to the first day of the next month
		date.setDate(1);
		date.setMonth(date.getMonth() + 1);
		return formatDateFromString(date.toISOString());
	}

    function Agree(){
        //show confirmation.
        setShowConfirmation(true);
    }

    function Disagree(){
        //show disagreement notice
        setShowDisagree(true);
    }

    async function submitForm(){
        if(pendingChange.contract_id === 0)
        {
            alert("There was a problem, please reload the page and try again.");
            return;
        } 
            
        setLoading(true);
        const token = await LocalStorageManager.getToken();
        const res = await ComunaAPI.ApproveContractChanges(token, pendingChange.contract_id);
        if(res){
            window.location.href = "/";
        }
    }

    return(
        <div className="flex flex-col w-full px-20 py-10" style={{maxWidth:1224,  margin:"auto"}}>
            <b className="w-full text-3xl font-extrabold comuna-purple mb-8">Review contract changes</b>

            <div className="bg-comuna-light p-4 rounded-xl mb-6 comuna-purple">
                <p className="font-bold comuna-purple">Any changes to contract details will only apply on {GetUpdateDate()}</p>
                <p className="comuna-purple">Changes to the contract are always only applied in the following month.</p>
            </div>

            <div className="comuna-card p-4 pt-6">
                
                <ContractorProfilePicture contractor={user}/>
                
                <span className="comuna-input-label">Role Description</span>
                
                <div className="bg-gray-1 rounded-xl p-4">
                    {contracts[0].role_description}
                </div>
                
                <div className="flex flex-row">
                    <div className="flex flex-col w-full mr-4">
                        <span className="comuna-input-label">Payment frequency</span>
                        <div className="bg-gray-1 rounded-xl p-4">
                            {pendingChange.payment_cadence}
                        </div>
                    </div>
                    <div className="flex flex-col w-full">
                        <span className="comuna-input-label">Payment amount</span>
                        <div className="bg-gray-1 rounded-xl p-4">
                            ${pendingChange.salary.toLocaleString("en-US")}
                        </div>
                    </div>
                </div>

                <span className="comuna-input-label">Benefits</span>

                <div className="flex flex-row h-fit w-full rounded-md mt-2 flex-wrap">
                    {pendingChange.benefits.map((benef: any, i: number) => {
                            return (
                                <BenefitItem
                                    key={i}
                                    benefit={benef}
                                    onDelete={null}
                                    big={true}
                                    canDelete={false}
                                />
                            );
                        })}
                </div>

                <div className="flex flex-row mt-12">
                    <button className="comuna-secondary-button mr-4" onClick={Disagree}>I don't agree with these Changes</button>
                    <button className="comuna-primary-button" onClick={Agree}>Approve Changes</button>
                </div>

            </div>


            {showDisagree && createPortal(
            <div className="comuna-popup">
                <div className="comuna-card p-8 text-center relative" style={{maxWidth:512}}>
                    <button
								onClick={(e)=>{ setShowDisagree(false);  }}
								style={{ top: 16, right: 16, position: "absolute" }}>
								<img src={xIcon} alt="close icon" style={{ width: 19 }} />
					</button>
                    <h2>Something doesn't look right?</h2>
                    <p className="mt-8">If something about these changes does not look right you should contact your employer about this.</p>
                    <p className="mt-2" >you can also create a Help Ticket and someone from The MUNA Team will come to help as well.</p>
                    <button className="comuna-secondary-button mt-4" onClick={(e)=>{ setShowDisagree(false);  }}>OK</button>
                </div>
            </div>, document.body)}
            
            {showConfirmation && createPortal(
            <div className="comuna-popup">
                <div className="comuna-card p-8 text-center" style={{maxWidth:512}}>
                    <h2>Approve changes?</h2>
                    <p className="mt-8">Approve contract changes requested by <b className="font-bold comuna-purple">{organization}</b>?</p>
                    <p className="mt-2" >If you proceed, the changes will apply from:</p>
                    <div className="w-full rounded-lg bg-comuna-purple-transparent self-center my-8 p-4">
						<label className="text-4xl comuna-purple font-bold self-center">
							{GetUpdateDate()}
						</label>
					</div>
                    <div className="flex flex-row mt-4">
                        <button className="comuna-secondary-button mr-2" onClick={(e)=>{ setShowConfirmation(false);  }}>Cancel</button>
                        <button className="comuna-primary-button font-bold" onClick={submitForm}>{loading?<Loader/>:"Approve Changes"}</button>
                    </div>
                </div>
            </div>, document.body)}

        </div>
    )
}