import { Modal, Table } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import {
	FormatDateToLocalString,
	formatStringDateFromServer,
} from "../utils/formaters";
import InvoiceState from "./InvoiceState";
import { ComunaPanel } from "./ComunaPanel";

import { Loader } from "./loader";

import ChallengeInvoiceModal from "./ChallengeInvoiceModal";

const chevron = require("../img/icons/chevron-dark-up.png");

const bank_icon = require("../img/icons/bank.png");
const card_icon = require("../img/icons/credit-card.png");


const option = "bg-purple-light w-full p-4 rounded-xl mt-2 flex flex-row items-center cursor-pointer"
const optionSelected = "bg-purple-dark w-full p-4 rounded-xl mt-2 flex flex-row items-center cursor-pointer"

interface InvoiceDetailViewProps {
	invoice: any;
	close: () => void;
}

const InvoiceDetailView: React.FC<InvoiceDetailViewProps> = ({
	invoice,
	close,
}) => {
	const closeIcon = require("../img/icons/x.png");

	const [printing, setPrinting] = useState(false);
	const [seeDetails, setSeeDetails] = useState(false);

	const [paymentMethod, setPaymentMethod] = useState("bank_transfer");

	const [hasBeenChallenged, setHasBeenChallenged] = useState(false);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		console.log("detail view get invoice ");
		console.log(invoice);
	}, [invoice]);

	useEffect(() => {
		if (printing) {
			var printContents = document.getElementById("printablediv")!.innerHTML;
			var originalContents = document.body.innerHTML;
			document.body.innerHTML = printContents;
			window.print();
			document.body.innerHTML = originalContents;
			setPrinting(false);
		}
	}, [printing]);


	const payInvoice = async () => {
		setLoading(true);
		const token = await LocalStorageManager.getToken();
		var noIssues = true;

		var data = {
			id: invoice.id,
			update: "pay",
			payment_method: paymentMethod,
		};

		try {
			const invoicesReq = await ComunaAPI.PayInvoice(token, data);
			window.location.replace(invoicesReq.url);
		} catch (error) {
			noIssues = false;
			console.log(error);
			alert("There was an error with your request, please try again later.");
		} finally {
			if (noIssues) {
				console.log("no issues");
			}
			setLoading(false);
		}
	};

	async function Print() {
		setPrinting(true);
	}

	const total = ()=>{
		return (
			invoice.total_salary +
			invoice.total_benefits +
			invoice.one_time_total +
			invoice.comuna_fee +
			invoice.payment_gateway
		);
	}

	const fee = ()=>{
		var t = total();
		var f = Math.ceil(t+0.3) / 0.97;
		return f-t;
	}

	const totalPlusFee = ()=>{
		var t = total();
		var f = Math.ceil(t+0.3) / 0.97;
		return f;
	}

	return (
		<div
			className="flex flex-col text-center pt-8 w-full h-full rounded-xl relative"
			style={{
				width: "100%",
			}}>

			<div className=" px-8">
				<h3 className="text-2xl font-bold text-black mb-6">Invoice details</h3>

				<div className="flex flex-row" id="printablediv">
					<div className="flex flex-col w-full">
						<p className="font-bold text-black text-left mb-2">Invoice details</p>

						<div className="px-6 py-4 bg-purple-light rounded-xl">
							<div className="flex flex-row w-full justify-between">
								<div className="flex flex-col text-left text-sm">
									<span>Invoice</span>
									<b className="mt-2">
										{formatStringDateFromServer(invoice.billing_cycle)}
									</b>
								</div>
								<div className="flex flex-col text-left text-sm">
									<span>Pay before date</span>
									<b className="mt-2">
										{formatStringDateFromServer(invoice.billing_cycle)}
									</b>
								</div>
								<div className="flex flex-col text-left text-sm">
									<span>Payment date</span>
									<b className="mt-2">
										{invoice.date_payed === null
											? "pending"
											: formatStringDateFromServer(invoice.date_payed)}
									</b>
								</div>
								<div className="flex flex-col text-left text-sm">
									<span>Amount</span>
									<b className="mt-2">
										{"$ " +total().toLocaleString("En-us").split(".")[0]}
									</b>
								</div>


								<div className="flex flex-col text-right text-sm">
									<span className="text-left">Status</span>
									<b>
										<InvoiceState status={invoice.status} className="text-xs" />
									</b>
								</div>

								<div className="flex flex-col text-right text-sm justify-center">
									<button onClick={() => setSeeDetails(!seeDetails)}>
										<img
											src={chevron}
											style={{
												width: 18,
												height: 12,
												transform: seeDetails ? "scale(1,1)" : "scale(1,-1)",
											}}
											alt=""
										/>
									</button>
								</div>
							</div>

						<div
							style={{
								maxHeight: seeDetails ? 1000 : 0,
								overflow: "hidden",
								transition: "all ease 0.8s",
							}}
							className="flex flex-col w-full mt-3">
							<div
								dangerouslySetInnerHTML={{ __html: invoice.invoice_details }}
								className="bg-white rounded-lg p-2 comuna-small-table"
							/>
						</div>
						</div>

						{invoice.status!="payed" && <div>
							<p className="font-bold text-black text-left mt-6">Select your payment method</p>
							
							<div className={paymentMethod==="bank_transfer"?optionSelected:option} onClick={()=>{ setPaymentMethod("bank_transfer") }}>
								<div className="flex flex-row bg-white p-4 rounded-full mr-6">
									<img src={bank_icon} alt="" style={{height:28, width:28}} />
								</div>
								<div className="flex flex-col flex-1 text-left">
									<b className="text-black text-md">Bank transfer</b>
									<p className="text-black text-md">ACH transfers are covered by MUNA</p>
								</div>
								<div className="flex flex-col text-right">
									<b className="text-xl text-black">Free</b>
									<p className="text-md text-black">Payment Gateway Fee</p>
								</div>
							</div>

							<div className={paymentMethod==="credit_card"?optionSelected:option}  onClick={()=>{ setPaymentMethod("credit_card") }}>
								<div className="flex flex-row bg-white p-4 rounded-full mr-6">
									<img src={card_icon} alt="" style={{height:28, width:28}} />
								</div>
								<div className="flex flex-col flex-1 text-left">
									<b className="text-black text-md">Credit card</b>
									<p className="text-black text-md">2.9% + .30c per transaction</p>
								</div>
								<div className="flex flex-col text-right">
									<b className="text-xl text-black comuna-purple">$ {fee().toLocaleString("En-us").split(".")[0]}</b>
									<p className="text-md text-black">Payment Gateway Fee</p>
								</div>
							</div>
						</div>}
					</div>

					{invoice.status != "payed" && <div className="flex flex-col ml-4" style={{width:424}}>
						<p className="font-bold text-black text-left mb-2">Cost Summary</p>
						<div className="bg-purple-light p-4 rounded-xl">
							<div className="flex flex-row justify-between items-center">
								<p className="text-black text-sm">MUNA invoice</p>
								<b>{"$ " +total().toLocaleString("En-us").split(".")[0]}</b>
							</div>
							<div className="flex flex-row justify-between">
								<p className="text-black text-sm">Payment Gateway Fee</p>
								{paymentMethod==="bank_transfer" && <b className="text-green-500 font-bold">Free</b>}
								{paymentMethod==="credit_card" && <b className="font-bold">$ {fee().toLocaleString("En-us").split(".")[0]}</b>}
							</div>
							<div className="flex flex-row justify-between">
								<p className="text-black text-sm">Total</p>
								<b className="text-2xl comuna-purple">$ {(paymentMethod==="bank_transfer"? total() : totalPlusFee()).toLocaleString("En-us").split(".")[0]}</b>
							</div>

							{invoice.status != "payed" && <div>
								<button className="comuna-primary-button mt-4" onClick={payInvoice}>{loading?<Loader/>:"Pay"}</button>
								{invoice.status!="payed" && invoice.status!="challenged" && <button className="comuna-secondary-button mt-4" onClick={()=>{setHasBeenChallenged(true)}}>Challenge </button>}
							</div>}
						</div>
					</div>}

				</div>
				
			</div>


			{hasBeenChallenged &&
			createPortal(
			<ComunaPanel onClose={()=>{ setHasBeenChallenged(false)  }}>
				<ChallengeInvoiceModal
					close={()=>{ setHasBeenChallenged(false) }}
					invoice={invoice}
				/>
			</ComunaPanel>, document.body)}

		</div>
	);
};

export default InvoiceDetailView;
